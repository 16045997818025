import { ImageContent } from '@app/assets/ImageContent'
import { getImageUrlFromImageType } from '@app/utils/imageUtils'

import { SubPackageOverview } from '../model/types'
import { getBgColor } from '../utils/getBgColor'
import { ProgressCircle } from './ProgressCircle'

type Props = {
  data: SubPackageOverview
  onClick: (subPackageId: string) => void
}

export const SubPackage = ({ data, onClick }: Props) => {
  if (!data) {
    return null
  }

  const { id, image, imageBackground, name, isEnabled, isFavorite, isStarted, currentProgress, currentSuccessMeasure } =
    data

  const imageUrl = getImageUrlFromImageType(image)

  const successMeasure = currentSuccessMeasure || 0
  const progress = currentProgress || 0

  return (
    <>
      {!isEnabled && (
        <>
          <svg
            className="absolute right-0 top-0 z-[100]"
            width="53"
            height="59"
            viewBox="0 0 53 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.210938 25.4314C0.211088 14.2051 0.210938 6.85202e-05 0.210938 6.85202e-05L53 0V58.3807C50.07 53.8758 44.6865 53.5362 31.4278 53.5362C9.60594 53.5362 0.210691 43.821 0.210938 25.4314Z"
              fill="#7141BF"
            />
          </svg>
          <img
            className="absolute right-1 top-1 z-[101] h-10 w-10 rounded-full"
            src={ImageContent.library.packageLock}
            alt="packageLock"
          />
        </>
      )}

      <div
        style={!isEnabled ? { opacity: 0.5 } : {}}
        className="relative z-30 flex h-auto w-36 cursor-pointer flex-col gap-2 overflow-hidden rounded-lg hover:brightness-110"
        onClick={() => isEnabled && onClick(id)}
      >
        <div className="relative flex h-auto flex-col rounded-2xl" style={{ background: getBgColor(imageBackground) }}>
          <img
            className="h-36 w-36 rounded-2xl object-cover bg-blend-overlay"
            src={imageUrl}
            alt={name}
            style={{ background: 'linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.39) 88.83%)' }}
          />
        </div>
        <div className="line-clamp-1 text-base font-medium text-neutral200">{name}</div>
        {isStarted && (
          <>
            <svg
              className="absolute right-0 top-0"
              width="53"
              height="59"
              viewBox="0 0 53 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.210938 25.4314C0.211088 14.2051 0.210938 6.85202e-05 0.210938 6.85202e-05L53 0V58.3807C50.07 53.8758 44.6865 53.5362 31.4278 53.5362C9.60594 53.5362 0.210691 43.821 0.210938 25.4314Z"
                fill="#7141BF"
              />
            </svg>

            {progress === 100 && successMeasure >= 50 ? (
              <img
                className="absolute right-[1px] top-[1px] h-12 w-12 rounded-full"
                src={
                  successMeasure >= 90
                    ? ImageContent.library.starGold
                    : successMeasure >= 70
                      ? ImageContent.library.starSilver
                      : ImageContent.library.starBronze
                }
                alt="favoriteEclipse"
              />
            ) : (
              <ProgressCircle progress={progress} />
            )}
          </>
        )}

        {isFavorite && (
          <div className="absolute left-1 top-[108px] flex h-8 w-8 items-center justify-center">
            <img className="h-8 w-8 rounded-full" src={ImageContent.library.favoriteEclipse} alt="favoriteEclipse" />
            <img
              className="absolute top-1 h-7 w-7 rounded-full"
              src={ImageContent.library.favoriteHeart}
              alt="favoriteEclipse"
            />
          </div>
        )}
      </div>
    </>
  )
}
