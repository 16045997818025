import { createWithEqualityFn } from 'zustand/traditional'

type TokenStoreType = {
  accessToken: string | null
  refreshToken: string | null
  isLoggedWithQR: boolean

  newRegistrationData?: {
    classroomId: string
    classroomLoginCode: string
  }
}

const initialState: TokenStoreType = {
  isLoggedWithQR: false,
  refreshToken: null,
  accessToken: null
}

const useStore = createWithEqualityFn(() => initialState)

export const localTokenStore = {
  saveAccessToken: (accessToken: string): void => {
    useStore.setState({ accessToken })
  },
  saveRefreshToken: (refreshToken: string): void => {
    useStore.setState({ refreshToken })
  },
  setIsLoggedWithQR: (isLoggedWithQR: boolean): void => {
    useStore.setState({ isLoggedWithQR })
  },

  setNewRegistrationData: (newRegistrationData: { classroomId: string; classroomLoginCode: string }): void => {
    useStore.setState({ newRegistrationData })
  },

  clearRegistrationData: (): void => {
    useStore.setState({ newRegistrationData: undefined })
  },

  setRefreshToken: (refreshToken: string): void => {
    useStore.setState({ refreshToken })
  },
  getRefreshToken: (): string | null => {
    const { refreshToken } = useStore.getState()
    return refreshToken
  },
  setAccessToken: (accessToken: string): void => {
    useStore.setState({ accessToken })
  },
  getAccessToken: (): string | null => {
    const { accessToken } = useStore.getState()
    return accessToken
  },

  clearStore: () => {
    useStore.setState(initialState, true)
  },

  useStore
}
