import { useEffect, useRef, useState } from 'react'
import PinField from 'react-pin-field'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useToggle } from 'react-use'
import {
  CapacitorBarcodeScanner,
  CapacitorBarcodeScannerCameraDirection,
  CapacitorBarcodeScannerTypeHint
} from '@capacitor/barcode-scanner'
import { Scanner } from '@yudiel/react-qr-scanner'
import classNames from 'classnames'

import { useTimeout } from '@foxino/components-common'

import { SvgContent } from '@app/assets/SvgContent'
import { Text } from '@app/components'
import { useTranslation } from '@app/locales'
import { GAME_CODE_PARAM, LOGIN_CODE_PARAM } from '@app/modules/common/model/searchCodeParams'
import { showToast } from '@app/utils/commonUtils'
import { logError } from '@app/utils/logsUtils'
import { isNative, isTablet } from '@app/utils/platformUtils'

import { InputTypeSwitch } from './InputTypeSwitch'

type Props = {
  onCodeRecognized: (code: string, gameCode?: string) => Promise<boolean>
}

const LENGTH_OF_PIN = 6

export const QrCodeInput = ({ onCodeRecognized }: Props) => {
  const [isQrHandlingAllowed, setIsQRHandlingAllowed] = useState<boolean>(true)
  const [code, setCode] = useState<string>('')
  const [gameCode, setGameCode] = useState<string>('')
  const isCodeValid = useRef(false)
  const ref = useRef<HTMLInputElement[]>([])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { t } = useTranslation('login')
  const [isQrScan, toggle] = useToggle(false)
  const [callQrCodeResetTimeout] = useTimeout(1000)
  const [callSetCodeFromParams] = useTimeout(500)

  const codeParam = searchParams.get(LOGIN_CODE_PARAM)

  useEffect(() => {
    for (let index = 0; index < LENGTH_OF_PIN; index++) {
      ref.current[index].placeholder = (index + 1).toString()
    }
  }, [])

  useEffect(() => {
    if (code.length === 0) {
      for (let index = 0; index < LENGTH_OF_PIN; index++) {
        ref.current[index].placeholder = (index + 1).toString()
      }
    } else if (code.length < LENGTH_OF_PIN) {
      for (let index = 0; index < LENGTH_OF_PIN; index++) {
        ref.current[index].placeholder = ''
      }
    } else if (code.length === LENGTH_OF_PIN) {
      setIsQRHandlingAllowed(false)
      handleOnConfirmCode()
    }
  }, [code])

  useEffect(() => {
    if (codeParam) {
      const codeFromParam = codeParam?.trim()

      if (codeFromParam?.length === LENGTH_OF_PIN) {
        callSetCodeFromParams(() => setCode(codeFromParam))
      } else {
        removeCodeFromUrl()
      }
    }
  }, [searchParams])

  useEffect(() => {
    if (isNative() && !codeParam) {
      handleToggle()
    }
  }, [])

  const handleOnQrInputChange = (numericText: string) => {
    setCode(numericText)
  }

  const handleOnConfirmCode = async () => {
    if (isCodeValid.current) {
      return
    }

    const isSuccessful = await onCodeRecognized(code, gameCode)

    if (isSuccessful) {
      isCodeValid.current = true
      setCode('')
      setIsQRHandlingAllowed(true)
    } else {
      logError(new Error('Login Code is not valid'), 'QrCodeInput', 'handleOnConfirmCode', t('loginQR.qrError'))

      removeCodeFromUrl()

      callQrCodeResetTimeout(() => {
        setIsQRHandlingAllowed(true)
        setCode('')
      })
    }
  }

  const removeCodeFromUrl = () => {
    searchParams.delete(LOGIN_CODE_PARAM)
    navigate({ search: `?${searchParams.toString()}` })
  }

  const handleToggle = () => {
    if (isNative()) {
      CapacitorBarcodeScanner.scanBarcode({
        hint: CapacitorBarcodeScannerTypeHint.QR_CODE,
        cameraDirection: CapacitorBarcodeScannerCameraDirection.BACK,
        web: { showCameraSelection: true },
        scanInstructions: t('loginQR.title')
      })
        .then((data) => {
          handleOnQrLoaded(data.ScanResult)
        })
        .catch((error) => {
          logError(error, 'QrCodeInput', 'handleToggle', undefined, false)
          ref.current[0].focus()
        })
    } else {
      toggle()
    }
  }

  const handleOnQrLoaded = (qrCode: string) => {
    if (!code && isQrHandlingAllowed) {
      const qrcodeUrl = new URL(qrCode)
      const loginCode = qrcodeUrl.searchParams.get(LOGIN_CODE_PARAM)
      const newGameCode = qrcodeUrl.searchParams.get(GAME_CODE_PARAM)

      setGameCode(newGameCode || '')

      if (loginCode?.length === LENGTH_OF_PIN) {
        setCode(loginCode)
      } else if (qrCode.length === LENGTH_OF_PIN) {
        setCode(qrCode)
      } else {
        showToast(t('loginQR.qrError'), { type: 'error' })

        searchParams.delete(LOGIN_CODE_PARAM)
        navigate({ search: `?${searchParams.toString()}` })
        ref.current[0].focus()
      }
    }
  }

  return (
    <div
      className={classNames(
        'absolute top-[80px] w-full',
        isQrScan
          ? isTablet()
            ? 'h-[70%]'
            : 'h-[90%]'
          : // because the keyboard in the tablets takes a lot of space
            isTablet()
            ? isNative()
              ? 'h-[calc(50%-80px-var(--safe-area-inset-top))]'
              : 'h-[calc(45%-80px-var(--safe-area-inset-top))]'
            : 'h-[calc(68%-80px-var(--safe-area-inset-top))]'
      )}
    >
      <div className="flex w-full flex-col items-center justify-start">
        {isQrScan ? (
          isNative() ? null : (
            <div className="flex w-full flex-col items-center justify-center gap-4">
              <Text className="text-neutral50">{t('loginQR.scanDescription')}</Text>

              <div className="flex h-[350px] w-[350px] items-center justify-center overflow-hidden rounded-3xl">
                <Scanner
                  constraints={{ facingMode: 'environment' }}
                  onScan={(data) => handleOnQrLoaded(data[0].rawValue)}
                  onError={(error) => {
                    logError(error, 'QrCodeInput', 'handleOnQrLoaded', t('loginQR.scannerError'))
                    toggle()
                  }}
                />
              </div>
            </div>
          )
        ) : (
          <div className="mt-8 flex max-w-[480px] flex-col">
            <div className="flex w-full flex-row justify-between px-4">
              <PinField
                ref={ref}
                autoFocus
                className={classNames(
                  'focus:border-transparent w-[60px] bg-[transparent] pl-[6px] text-[4.6rem] text-neutral50 placeholder:text-neutral50/25 focus:outline-none focus:ring-0',
                  'opacity-100'
                )}
                length={LENGTH_OF_PIN}
                inputMode="numeric"
                onPasteCapture={(e) => {
                  e.preventDefault()

                  const data = e.clipboardData.getData('text/plain').split(' ').join('').slice(0, LENGTH_OF_PIN)
                  setCode(data)
                }}
                pattern="[0-9]*"
                validate={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']}
                onChange={handleOnQrInputChange}
              />
            </div>

            <div className="-mt-10 flex w-full flex-row justify-between px-4">
              {Array.from({ length: LENGTH_OF_PIN }).map((_, index) => {
                return <img key={index} className="w-[60px] object-contain" src={SvgContent.login.pinUnderline} />
              })}
            </div>
          </div>
        )}
        <InputTypeSwitch isQrScan={isQrScan} toggle={handleToggle} />
      </div>
    </div>
  )
}
