import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useInterval, useUnmount } from 'react-use'
import dayjs from 'dayjs'

import { ImageContent } from '@app/assets/ImageContent'
import { Pages } from '@app/config/router/Pages'
import { GetActiveTasksDocument, GetFinishedTasksDocument, useProcessExecutionExitMutation } from '@app/data'

import { lessonInputsStore } from '../../store'

type Props = {
  startCountDown?: boolean
}

const LIMIT_WARNING_IN_S = 60 // 60s

export const TestTimeCountdown = ({ startCountDown }: Props) => {
  const navigate = useNavigate()

  const { timeLimitInSeconds, nextFlashCardInput } = lessonInputsStore.useStore((store) => ({
    timeLimitInSeconds: store.timeLimitInSeconds,
    nextFlashCardInput: store.nextFlashCardInput
  }))

  const [currTime, setCurrTime] = useState(timeLimitInSeconds || 2000)

  const [exitExecution] = useProcessExecutionExitMutation()

  useInterval(() => {
    if (!startCountDown) {
      return
    }

    // kicks the user out of the test when the time limit is reached
    if (currTime === 0) {
      navigate(Pages.DASHBOARD)
      return
    }

    setCurrTime((value) => value - 1)
  }, 1000)

  useUnmount(() => {
    if (!startCountDown) {
      return
    }

    exitExecution({
      variables: {
        exerciseExitInput: {
          elapsedTimeMilliseconds: timeLimitInSeconds ? (timeLimitInSeconds - currTime) * 1000 : 0,
          learningExecutionId: nextFlashCardInput.learningExecutionId
        }
      },
      refetchQueries: [GetActiveTasksDocument, GetFinishedTasksDocument]
    })
  })

  const duration = dayjs.duration(currTime, 'seconds')
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  return (
    <div className="flex h-full w-full flex-row items-center justify-center gap-1">
      <img className="-mt-1 h-6 w-6" src={ImageContent.lesson.timer} alt="timer" />
      <span style={{ color: currTime > LIMIT_WARNING_IN_S ? '#414141' : '#FF004D' }} className="text-sm">
        {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
      </span>
    </div>
  )
}
