import classnames from 'classnames'

import { Typography, TypographyProps, WeightTextType } from '.'

type Props = Omit<TypographyProps, 'variant'> & {
  size?: HeadingSizeType
}

export type HeadingSizeType = 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs'

const sizeToHeadingType: Record<HeadingSizeType, React.ElementType> = {
  xxl: 'h1',
  xl: 'h1',
  l: 'h2',
  m: 'h3',
  s: 'h4',
  xs: 'h5',
  xxs: 'h6'
}

const sizeToStyle: Record<HeadingSizeType, React.ComponentProps<'h1'>['className']> = {
  xxl: 'text-5xl tracking-[-0.48px] leading-[3.5rem]',
  xl: 'text-4xl tracking-[-0.36px]',
  l: 'text-[2rem] leading-[2.75rem]',
  m: 'text-2xl',
  s: 'text-xl',
  xs: 'text-lg',
  xxs: 'text-base'
}

const weightToStyle: Record<WeightTextType, React.ComponentProps<'h1'>['className']> = {
  extraBold: 'font-extrabold',
  bold: 'font-bold',
  semibold: 'font-semibold',
  medium: 'font-medium',
  regular: 'font-normal'
}

/**
 * @group Components
 * @category Atoms
 */
export const Heading = ({ className, weight = 'bold', size = 'xl', children, ...props }: Props) => {
  const HeadingComponent = sizeToHeadingType[size]
  const sizeStyle = sizeToStyle[size]
  const weightStyle = weightToStyle[weight]

  return (
    <Typography Component={HeadingComponent} className={classnames(sizeStyle, weightStyle, className)} {...props}>
      {children}
    </Typography>
  )
}
