import axios from 'axios'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import Constants from '@app/constants'
import { initApolloClient } from '@app/modules/common/actions/apolloActions'
import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'
import { lessonActiveFlashCardStore, lessonInputsStore, lessonSessionStore } from '@app/modules/lesson/store'
import { logError } from '@app/utils/logsUtils'

import { AuthState } from '../model/enums'
import { KeycloakTokenResponse, UserInfoDTO } from '../model/types'
import { authStore } from '../store/authStore'
import { localTokenStore } from '../store/localTokenStore'
import { getFullName } from '../utils/getFullName'
import {
  addAuthorizationTokenToAxios,
  addUnauthorizedHandlerToAxios,
  removeAuthorizationTokenFromAxios
} from './axiosActions'
import {
  getTimeToRefreshToken,
  isTokenStillValid,
  readAccessToken,
  readRefreshToken,
  saveAccessToken,
  saveRefreshToken
} from './localTokenActions'

export const initAuth = () => {
  addUnauthorizedHandlerToAxios(logout, getAccessToken)
  initApolloClient()
}

export const getAccessToken = async (minValidity = 30): Promise<boolean> => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  await kc.updateToken(minValidity)

  return true
}

// TODO: remove all methods which work with tokens inside app
export const login = async (username: string, password: string): Promise<boolean> => {
  authStore.setAuthState(AuthState.LOGIN_IN_PROGRESS)
  const sanitizedUsername = sanitizeInput(username)
  const sanitizedPassword = sanitizeInput(password)

  try {
    const params = new URLSearchParams()
    params.append('grant_type', 'password')
    params.append('username', sanitizedUsername)
    params.append('password', sanitizedPassword)
    params.append('client_id', Constants.AUTH_KEYCLOAK_CLIENT_ID)

    const { data } = await axios.post<KeycloakTokenResponse>(Constants.AUTH_LOGIN_URL, params, {
      headers: { 'Content-Type': Constants.AUTH_CONTENT_TYPE }
    })

    handleSuccessfulLogin(data)

    return true
  } catch (error) {
    logError(error, 'authActions', 'login', 'AUTH: get new access token', false)

    logout()

    return false
  }
}

const getNewAccessToken = async (refreshToken: string): Promise<boolean> => {
  try {
    const { data } = await axios.post<KeycloakTokenResponse>(
      Constants.AUTH_REFRESH_TOKEN_URL,
      {
        grant_type: 'refresh_token',
        client_id: Constants.AUTH_KEYCLOAK_CLIENT_ID,
        refresh_token: refreshToken
      },
      { headers: { 'Content-Type': Constants.AUTH_CONTENT_TYPE } }
    )

    removeAuthorizationTokenFromAxios()
    handleSuccessfulLogin(data)

    return true
  } catch (error) {
    logError(error, 'authActions', 'getNewAccessToken', 'AUTH: get new access token')
    return false
  }
}

export const handleSuccessfulLogin = (data: KeycloakTokenResponse): void => {
  console.info('AUTH: login successful')

  const { preferred_username, realm_access, email, family_name, given_name } = jwtDecode<JwtPayload & UserInfoDTO>(
    data.access_token
  )

  // authStore.setUserInfo(preferred_username, email, realm_access?.roles)
  authStore.setUserInfo({
    email,
    firstName: given_name,
    lastName: family_name,
    roles: realm_access?.roles || [],
    fullName: getFullName(given_name, family_name),
    userId: preferred_username
  })

  addAuthorizationTokenToAxios(data.access_token)

  saveAccessToken(data.access_token)
  saveRefreshToken(data.refresh_token)
  authStore.setAuthState(AuthState.AUTHENTICATED)

  activateAutomaticRefreshToken()
}

export const logout = (): void => {
  try {
    deactivateAutomaticRefreshToken()
    removeAuthorizationTokenFromAxios()
    authStore.setAuthState(AuthState.NEED_TO_LOGIN)

    console.info('AUTH: logout')

    const keycloak = authStore.useStore.getState().keycloak
    const url = keycloak?.createLogoutUrl()

    clearAllStores()

    window.location.href = url || window.location.origin
  } catch (error) {
    logError(error, 'authActions', 'logout')
  }
}

const clearAllStores = (): void => {
  authStore.clearStore()
  localTokenStore.clearStore()
  localSettingsStore.clearStore()
  lessonSessionStore.clearStore()
  lessonInputsStore.clearStore()
  lessonActiveFlashCardStore.clearStore()
}

export const updateAuthentication = async (): Promise<boolean> => {
  const accessToken = readAccessToken()
  const refreshToken = readRefreshToken()

  console.info('AUTH: update access token:', accessToken)
  if (isTokenStillValid(accessToken) && isTokenStillValid(refreshToken)) {
    console.info('AUTH: access token is valid')
    handleSuccessfulLogin({ access_token: accessToken, refresh_token: refreshToken })
    await activateAutomaticRefreshToken()
    return true
  } else {
    const refreshToken = readRefreshToken()

    console.info('AUTH: refresh token', refreshToken)
    if (isTokenStillValid(refreshToken)) {
      console.info('AUTH: refresh token is valid, trying to get new access token')
      authStore.setAuthState(AuthState.LOGIN_IN_PROGRESS)
      const hasNewToken = await getNewAccessToken(refreshToken)

      if (!hasNewToken) {
        logout()
      }

      return hasNewToken
    } else {
      console.info('AUTH: refresh token is not valid, need to login')
      authStore.setAuthState(AuthState.NEED_TO_LOGIN)
      logout()
      return false
    }
  }
}

const clearRefreshTokenTimer = (): void => {
  const refreshTokenTimerId = authStore.getRefreshTokenTimerId()
  if (refreshTokenTimerId) {
    clearTimeout(refreshTokenTimerId)
    authStore.clearRefreshTokenTimerId()
  }
}

const activateAutomaticRefreshToken = async (): Promise<void> => {
  clearRefreshTokenTimer()

  // has valid refresh token -> activate automatic refresh
  const refreshToken = readRefreshToken()
  const accessToken = localTokenStore.getAccessToken()

  if (isTokenStillValid(refreshToken) && accessToken) {
    const timeToRefreshToken = getTimeToRefreshToken(accessToken)
    if (timeToRefreshToken >= 0) {
      const min = Math.floor(timeToRefreshToken / 1000 / 60)
      const sec = Math.floor((timeToRefreshToken / 1000) % 60)
      console.info(`AUTH: activate automatic refresh token in ${min}:${sec}s`)

      const timeoutId = setTimeout(async () => {
        console.info('AUTH: activate automatic refresh token now')
        const hasNewToken = await getNewAccessToken(refreshToken)

        if (!hasNewToken) {
          if (isTokenStillValid(accessToken, 0)) {
            console.info('AUTH: access token is valid for next 5 minutes')
            authStore.setAuthState(AuthState.WILL_BE_LOGGED_OUT)
            return true
          } else {
            console.info('AUTH: access token is not valid, need to login')
            authStore.setAuthState(AuthState.NEED_TO_LOGIN)
            logout()
            return false
          }
        }
      }, timeToRefreshToken)

      authStore.setRefreshTokenTimerId(timeoutId)
    } else {
      console.info('AUTH: Token is expired, get new token')

      if (!refreshToken) {
        authStore.setAuthState(AuthState.NEED_TO_LOGIN)
        logout()

        return
      }

      const hasNewToken = await getNewAccessToken(refreshToken)
      if (!hasNewToken) {
        authStore.setAuthState(AuthState.NEED_TO_LOGIN)
        logout()
      }
    }
  }
}

export const deactivateAutomaticRefreshToken = (): void => {
  clearRefreshTokenTimer()
}

addUnauthorizedHandlerToAxios(logout, updateAuthentication)

function sanitizeInput(input: string): string {
  // Remove any non-alphanumeric characters
  return input.replace(/[^a-zA-Z0-9.@]/g, '')
}
