import BalloonCloudBottom1 from './img/balloons/cloud_bottom_1.png'
import BalloonCloudBottom2 from './img/balloons/cloud_bottom_2.png'
import BalloonCloudBottom3 from './img/balloons/cloud_bottom_3.png'
import BalloonCloudTop1 from './img/balloons/cloud_top_1.png'
import BalloonCloudTop2 from './img/balloons/cloud_top_2.png'
import BalloonCloudTop3 from './img/balloons/cloud_top_3.png'
import BalloonCloudsBottom1 from './img/balloons/clouds_bottom_1.png'
import BalloonCloudsBottom2 from './img/balloons/clouds_bottom_2.png'
import BalloonExplosionGreen from './img/balloons/explosion_green.gif'
import BalloonExplosionOrange from './img/balloons/explosion_orange.gif'
import BalloonExplosionPink from './img/balloons/explosion_pink.gif'
import BalloonExplosionPurple from './img/balloons/explosion_purple.gif'
import BalloonExplosionYellow from './img/balloons/explosion_yellow.gif'
import BalloonGreen from './img/balloons/green_balloon.png'
import BalloonOrange from './img/balloons/orange_balloon.png'
import BalloonPink from './img/balloons/pink_balloon.png'
import BalloonPurple from './img/balloons/purple_balloon.png'
import BalloonSkyBlue from './img/balloons/sky_blue.png'
import BalloonYellow from './img/balloons/yellow_balloon.png'
import CommonExitDialogBg from './img/common/exit_dialog_bg.webp'
import CommonIconFoxino from './img/common/icon_foxino.webp'
import CommonInsufficientRole from './img/common/insufficient_roles.webp'
import CommonPurpleFoxinoClosedEyes from './img/common/purple_foxino_closed_eyes.webp'
import VersionUpdate from './img/common/version_update.webp'
import Error1 from './img/error/error_img_1.webp'
import Error2 from './img/error/error_img_2.webp'
import Error3 from './img/error/error_img_3.webp'
import Error4 from './img/error/error_img_4.webp'
import Error5 from './img/error/error_img_5.webp'
import LeaderboardBronzeColumn from './img/leaderboard/bronze_column.webp'
import LeaderboardGoldColumn from './img/leaderboard/gold_column.webp'
import LeaderboardSilverColumn from './img/leaderboard/silver_column.webp'
import LearningPathSliceBg from './img/learningPath/bg.webp'
import LearningPathCloud1 from './img/learningPath/cloud_1.webp'
import LearningPathCloud2 from './img/learningPath/cloud_2.webp'
import LearningPathClouds from './img/learningPath/clouds.webp'
import LearningPathCloudsGround from './img/learningPath/clouds_ground.webp'
import LearningPathLandscape from './img/learningPath/landscape.webp'
import LearningPathPlanet1 from './img/learningPath/planet_1.webp'
import LearningPathPlanet2 from './img/learningPath/planet_2.webp'
import LearningPathSky from './img/learningPath/sky.webp'
import LearningPathSun from './img/learningPath/sun.webp'
import LearningPathSunLayer from './img/learningPath/sun_layer.webp'
import LearningPathWater from './img/learningPath/water.webp'
import LessonCloud from './img/lesson/lesson_cloud.webp'
import LessonLoadingBarBg from './img/lesson/loading_bar_bg.webp'
import LessonLoadingFill from './img/lesson/loading_bar_fill.webp'
import LessonMicRecording from './img/lesson/mic_recording.webp'
import LessonSoundSpeaker from './img/lesson/sound_speaker.webp'
import LessonSpeaker from './img/lesson/speaker.webp'
import LessonTestTimer from './img/lesson/timer.webp'
import LessonTurtleBtn from './img/lesson/turtle_btn.webp'
import LibraryFavoriteEclipse from './img/library/favorite_eclipse.webp'
import LibraryFavoriteHeart from './img/library/favorite_heart.webp'
import LibraryFavoriteInactive from './img/library/favorite_inactive.webp'
import LibraryPackageLock from './img/library/lock.webp'
import LibraryPackageStarBronze from './img/library/star_bronze.webp'
import LibraryPackageStarGold from './img/library/star_gold.webp'
import LibraryPackageStarSilver from './img/library/star_silver.webp'
import QuizGameArrowDown from './img/quizGame/arrow_drop_down_red.png'
import QuizGameArrowUp from './img/quizGame/arrow_drop_up_green.png'
import QuizGameArrowSamePlace from './img/quizGame/arrow_same_place.png'
import QuizGameBadge from './img/quizGame/badge.webp'
import QuizGameBottomBarBg from './img/quizGame/bottom_bar_bg.webp'
import QuizGameCheckmark from './img/quizGame/checkmark.webp'
import QuizGameCheckmarkLines from './img/quizGame/checkmark_lines.webp'
import QuizGameConfetti from './img/quizGame/confetti.webp'
import QuizGameEclipse from './img/quizGame/eclipse.svg'
import QuizGameEclipseInner from './img/quizGame/eclipse_inner.svg'
import QuizGameEmojiPeople from './img/quizGame/emoji_people.svg'
import QuizGameExitBanner from './img/quizGame/exit_banner.webp'
import QuizGameFirstWinner from './img/quizGame/first_winner.webp'
import QuizGameLogo from './img/quizGame/logo.webp'
import QuizGameQuestionFrameBg from './img/quizGame/question_frame_bg.webp'
import QuizGameRedCross from './img/quizGame/red_cross.webp'
import QuizGameSecondWinner from './img/quizGame/second_winner.webp'
import QuizGameThirdWinner from './img/quizGame/third_winner.webp'
import QuizGameTitleBg from './img/quizGame/title_bg.webp'
import QuizGameTrophy from './img/quizGame/trophy.webp'
import TasksAllDoneForToday from './img/tasks/all_done_for_today.webp'
import TasksHomeworkBg_1 from './img/tasks/homework_big_bg_1.webp'
import TasksHomeworkBg_2 from './img/tasks/homework_big_bg_2.webp'
import TasksHomeworkEven from './img/tasks/homework_even.webp'
import TasksHomeworkIcon from './img/tasks/homework_icon.webp'
import TasksHomeworkOdd from './img/tasks/homework_odd.webp'
import TasksHomeworkProgressBarFg from './img/tasks/homework_progress_bar_fg.webp'
import TasksHomeworkProgressBarTop from './img/tasks/homework_progress_bar_top.webp'
import TasksHomeworkVideoPoster from './img/tasks/homework_video_poster.webp'
import TasksRepetitionBg_1 from './img/tasks/repetition_big_bg_1.webp'
import TasksRepetitionEven from './img/tasks/repetition_even.webp'
import TasksRepetitionOdd from './img/tasks/repetition_odd.webp'
import TasksRepetitionProgressBarFg from './img/tasks/repetition_progress_bar_fg.webp'
import TasksRepetitionProgressBarTop from './img/tasks/repetition_progress_bar_top.webp'
import TasksRepetitionVideoPoster from './img/tasks/repetition_video_poster.webp'
import TasksSelfStudyBg_1 from './img/tasks/selfstudy_big_bg_1.webp'
import TasksSelfStudyEven from './img/tasks/selfStudy_even.webp'
import TasksSelfStudyIcon from './img/tasks/selfstudy_icon.webp'
import TasksSelfStudyOdd from './img/tasks/selfStudy_odd.webp'
import TasksSelfStudyProgressBarFg from './img/tasks/selfstudy_progress_bar_fg.webp'
import TasksSelfStudyProgressBarTop from './img/tasks/selfstudy_progress_bar_top.webp'
import TasksSelfStudyVideoPoster from './img/tasks/selfstudy_video_poster.webp'
import TasksContentHomework from './img/tasks/task_homework.webp'
import TasksContentRepetition from './img/tasks/task_repetition.webp'
import TasksContentSelfStudy from './img/tasks/task_selfstudy.webp'
import TasksContentTest from './img/tasks/task_test.webp'
import TasksTestBg_1 from './img/tasks/test_big_bg_1.webp'
import TasksTestEven from './img/tasks/test_even.webp'
import TasksTestIcon from './img/tasks/test_icon.webp'
import TasksTestOdd from './img/tasks/test_odd.webp'
import TasksTestProgressBarFg from './img/tasks/test_progress_bar_fg.webp'
import TasksTestProgressBarTop from './img/tasks/test_progress_bar_top.webp'
import TasksTestVideoPoster from './img/tasks/test_video_poster.webp'

export const ImageContent = {
  common: {
    purpleFoxinoClosedEyes: CommonPurpleFoxinoClosedEyes,
    insufficientRole: CommonInsufficientRole,
    exitDialogBg: CommonExitDialogBg,
    iconFoxino: CommonIconFoxino,
    versionUpdate: VersionUpdate
  },

  library: {
    favoriteEclipse: LibraryFavoriteEclipse,
    favoriteHeart: LibraryFavoriteHeart,
    favoriteInactive: LibraryFavoriteInactive,
    packageLock: LibraryPackageLock,
    starBronze: LibraryPackageStarBronze,
    starSilver: LibraryPackageStarSilver,
    starGold: LibraryPackageStarGold
  },

  tasks: {
    allDoneForToday: TasksAllDoneForToday,
    homework: TasksContentHomework,
    homeworkBg1: TasksHomeworkBg_1,
    homeworkBg2: TasksHomeworkBg_2,
    homeworkEven: TasksHomeworkEven,
    homeworkIcon: TasksHomeworkIcon,
    homeworkOdd: TasksHomeworkOdd,
    homeworkProgressBarFg: TasksHomeworkProgressBarFg,
    homeworkProgressBarTop: TasksHomeworkProgressBarTop,
    homeworkVideoPoster: TasksHomeworkVideoPoster,
    repetition: TasksContentRepetition,
    repetitionBg1: TasksRepetitionBg_1,
    repetitionEven: TasksRepetitionEven,
    repetitionOdd: TasksRepetitionOdd,
    repetitionProgressBarFg: TasksRepetitionProgressBarFg,
    repetitionProgressBarTop: TasksRepetitionProgressBarTop,
    repetitionVideoPoster: TasksRepetitionVideoPoster,
    selfStudy: TasksContentSelfStudy,
    selfStudyBg1: TasksSelfStudyBg_1,
    // selfStudyBg2: TasksSelfStudyBg_2, // TODO design missing
    selfStudyEven: TasksSelfStudyEven,
    selfStudyIcon: TasksSelfStudyIcon,
    selfStudyOdd: TasksSelfStudyOdd,
    selfStudyProgressBarFg: TasksSelfStudyProgressBarFg,
    selfStudyProgressBarTop: TasksSelfStudyProgressBarTop,
    selfStudyVideoPoster: TasksSelfStudyVideoPoster,
    test: TasksContentTest,
    testBg1: TasksTestBg_1,
    // testBg2: TasksTestBg_2, // TODO design missing
    testEven: TasksTestEven,
    testIcon: TasksTestIcon,
    testOdd: TasksTestOdd,
    testProgressBarFg: TasksTestProgressBarFg,
    testProgressBarTop: TasksTestProgressBarTop,
    testVideoPoster: TasksTestVideoPoster
  },

  leaderboard: {
    gold: LeaderboardGoldColumn,
    silver: LeaderboardSilverColumn,
    bronze: LeaderboardBronzeColumn
  },

  lesson: {
    lessonCloud: LessonCloud,
    loadingBarBg: LessonLoadingBarBg,
    loadingBarFill: LessonLoadingFill,
    micRecording: LessonMicRecording,
    soundSpeaker: LessonSoundSpeaker,
    timer: LessonTestTimer,
    turtleBtn: LessonTurtleBtn,
    speaker: LessonSpeaker
  },

  quizGame: {
    arrowDown: QuizGameArrowDown,
    arrowSamePlace: QuizGameArrowSamePlace,
    arrowUp: QuizGameArrowUp,
    badge: QuizGameBadge,
    bottomBarBg: QuizGameBottomBarBg,
    checkmark: QuizGameCheckmark,
    checkmarkLines: QuizGameCheckmarkLines,
    confetti: QuizGameConfetti,
    eclipse: QuizGameEclipse,
    eclipseInner: QuizGameEclipseInner,
    emojiPeople: QuizGameEmojiPeople,
    exitBanner: QuizGameExitBanner,
    firstWinner: QuizGameFirstWinner,
    logo: QuizGameLogo,
    questionFrameBg: QuizGameQuestionFrameBg,
    redCross: QuizGameRedCross,
    secondWinner: QuizGameSecondWinner,
    thirdWinner: QuizGameThirdWinner,
    titleBg: QuizGameTitleBg,
    trophy: QuizGameTrophy
  },

  learningPath: {
    cloudsGround: LearningPathCloudsGround,
    clouds: LearningPathClouds,
    cloud1: LearningPathCloud1,
    cloud2: LearningPathCloud2,
    landscape: LearningPathLandscape,
    planet1: LearningPathPlanet1,
    planet2: LearningPathPlanet2,
    sky: LearningPathSky,
    sliceBg: LearningPathSliceBg,
    sun: LearningPathSun,
    sunLayer: LearningPathSunLayer,
    water: LearningPathWater
  },

  error: {
    img1: Error1,
    img2: Error2,
    img3: Error3,
    img4: Error4,
    img5: Error5
  },

  ballon: {
    cloudsBottom1: BalloonCloudsBottom1,
    cloudsBottom2: BalloonCloudsBottom2,
    cloudBottom1: BalloonCloudBottom1,
    cloudBottom2: BalloonCloudBottom2,
    cloudBottom3: BalloonCloudBottom3,
    cloudTop1: BalloonCloudTop1,
    cloudTop2: BalloonCloudTop2,
    cloudTop3: BalloonCloudTop3,
    explosionGreen: BalloonExplosionGreen,
    explosionOrange: BalloonExplosionOrange,
    explosionPink: BalloonExplosionPink,
    explosionPurple: BalloonExplosionPurple,
    explosionYellow: BalloonExplosionYellow,
    green: BalloonGreen,
    orange: BalloonOrange,
    pink: BalloonPink,
    purple: BalloonPurple,
    yellow: BalloonYellow,
    skyBlue: BalloonSkyBlue
  }
}
