import { useEffect, useState } from 'react'
import { LiveUpdate } from '@capawesome/capacitor-live-update'

import { ImageContent } from '@app/assets/ImageContent'
import { Heading, Text } from '@app/components'
import Constants from '@app/constants'
import { useTranslation } from '@app/locales'
import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'
import { LessonProgressBar } from '@app/modules/lesson/components'
import { logError } from '@app/utils/logsUtils'

type Props = {
  onDownloadingEnd: () => void
  newVersion: string
}

export const NewVersionDownloading = ({ onDownloadingEnd, newVersion }: Props) => {
  const { t } = useTranslation(['error', 'common'])
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const downloadBundle = async () => {
      try {
        LiveUpdate.addListener('downloadBundleProgress', (progress) => {
          setProgress(progress.progress * 100)
        })

        const downloadUrl = `${Constants.APP_URL}/info/bundle.zip`
        console.info('[JS - version] Downloading new version from: ', downloadUrl)

        await LiveUpdate.downloadBundle({
          url: downloadUrl,
          bundleId: newVersion
        })

        console.info('[JS - version] Download completed, setting bundle: ', newVersion)

        await LiveUpdate.setNextBundle({ bundleId: newVersion })

        console.info('[JS - version] Bundle set, reloading app')
        localSettingsStore.setBuildBuildNumber(newVersion)

        await LiveUpdate.reload()
      } catch (error) {
        logError(error, 'NewVersionDownloading', 'downloadBundle', undefined, false)

        LiveUpdate.ready()
        onDownloadingEnd()
        return
      }
    }

    downloadBundle()
  }, [])

  return (
    <div className="mx-auto px-4">
      <div className="mt-12 flex flex-col items-center gap-2 text-primary100">
        <img src={ImageContent.common.versionUpdate} className="max-h-[38vh]" />
        <Heading size="m" className="text-center">
          {t('version.dialogTitle')}
        </Heading>
        <Text className="text-center">{t('version.dialogDescription')}</Text>
        <Heading size="s" className="mt-8 animate-bounce">
          {t('version.downloading')}
        </Heading>
        <div className="flex w-full items-center gap-4">
          <LessonProgressBar progress={progress} />
          <Text className="text-primary100">{Math.floor(progress)}%</Text>
        </div>
      </div>
    </div>
  )
}
