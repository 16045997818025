import { motion } from 'framer-motion'

import { useTimeout } from '@foxino/components-common'

import { Text } from '@app/components'
import { useTranslation } from '@app/locales'

type Props = {
  isClassroomSelected: boolean
  toggle: () => void
}

const OPTION_WIDTH = 111

export const ClassroomSwitch = ({ isClassroomSelected, toggle }: Props) => {
  const { t } = useTranslation('classroom')
  const [runBlockingTimeout, isTimeoutWaiting] = useTimeout(200)

  const handleToggle = () => {
    if (isTimeoutWaiting) {
      toggle()
    }
    runBlockingTimeout()
  }

  return (
    <div
      style={{
        background: '#DED9FF',
        padding: '2px',
        width: OPTION_WIDTH * 2 + 4 // 4 padding
      }}
      className={`${isClassroomSelected ? 'justify-end' : 'justify-start'} relative flex h-8 cursor-pointer flex-row rounded-2xl`}
      onClick={handleToggle}
    >
      <motion.div
        style={{ width: OPTION_WIDTH }}
        className="z-20 flex h-full items-center justify-center rounded-2xl bg-btn-primary-bg-gradient text-neutral50 shadow-btn-primary"
        layout
        transition={{ type: 'spring', duration: 0.25 }}
      >
        <Text size="s" weight="bold">
          {isClassroomSelected ? t('taskChosenClass') : t('taskAll')}
        </Text>
      </motion.div>

      <div
        style={{ width: OPTION_WIDTH, background: '#DED9FF' }}
        className="absolute left-0 z-10 flex h-[28px] items-center justify-center rounded-2xl text-[#2C0077]"
      >
        <Text size="s" weight="bold">
          {t('taskAll')}
        </Text>
      </div>

      <div
        style={{ width: OPTION_WIDTH, background: '#DED9FF' }}
        className="absolute right-0 z-10 flex h-[28px] items-center justify-center rounded-2xl text-[#2C0077]"
      >
        <Text size="s" weight="bold">
          {t('taskChosenClass')}
        </Text>
      </div>
    </div>
  )
}
