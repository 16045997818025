import { NetworkStatus, QueryHookOptions } from '@apollo/client'

import { GetStudentInfoQuery, GetStudentInfoQueryVariables, useGetStudentInfoQuery } from '@app/data'
import { localTokenStore } from '@app/modules/auth/store/localTokenStore'

export const useStudentInfo = (baseOptions?: QueryHookOptions<GetStudentInfoQuery, GetStudentInfoQueryVariables>) => {
  const tokenIsPresent = localTokenStore.useStore((state) => state.accessToken !== null)

  const dashboardQuery = useGetStudentInfoQuery({
    ...baseOptions,
    skip: !tokenIsPresent,
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-and-network',
    refetchWritePolicy: 'overwrite'
  })

  const refetchStudentInfo = () => {
    dashboardQuery.refetch()
  }

  const error = dashboardQuery.error
  const loading = dashboardQuery.loading

  return {
    refetchStudentInfo,
    studentInfo: dashboardQuery.data?.studentInfo,
    refetching: dashboardQuery.networkStatus === NetworkStatus.refetch,
    loading,
    error
  }
}
