import { createBreakpoint } from 'react-use'

// based on https://gs.statcounter.com/screen-resolution-stats/tablet/worldwide and https://gs.statcounter.com/screen-resolution-stats/desktop/worldwide
const breakpoints = {
  xs: 0, // mobile
  sm: 480, // tablet - minWidth
  md: 768, // tablet landscape - minWidth
  lg: 1024, // tablet - maxWidth; desktop - minWidth
  xl: 1280, // desktop - maxWidth
  xxl: 1440, // desktop - maxWidth
  xxxl: 1680 // desktop - maxWidth
}

export type Breakpoints = keyof typeof breakpoints

export const useBreakpoints = () => {
  const breakpointFunction = createBreakpoint(breakpoints)
  const breakpoint = breakpointFunction() as Breakpoints

  const isXxxl = breakpoint === 'xxxl'
  const isXxl = breakpoint === 'xxl' || isXxxl
  const isXl = breakpoint === 'xl' || isXxl
  const isLg = breakpoint === 'lg' || isXl
  const isMd = breakpoint === 'md' || isLg
  const isSm = breakpoint === 'sm' || isMd
  const isXs = breakpoint === 'xs' || isSm

  return { breakpoint, isXs, isSm, isMd, isLg, isXl, isXxl, isXxxl }
}
