import classnames from 'classnames'
import { forwardRef } from 'react'
import defaultImage from './defaultImage.svg'
import { getInitials } from '@foxino/components-common'
import { Tooltip } from '..'

type SizeType = 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl'

const sizeToStyle: Record<SizeType, string> = {
  xs: 'w-6 h-6',
  s: 'w-8 h-8',
  m: 'w-10 h-10',
  l: 'w-12 h-12',
  xl: 'w-14 h-14',
  '2xl': 'w-16 h-16',
  '3xl': 'w-20 h-20'
}

const sizeToPadding: Record<SizeType, string> = {
  xs: 'p-1',
  s: 'p-[6px]',
  m: 'p-[10px]',
  l: 'p-3',
  xl: 'p-[14px]',
  '2xl': 'p-4',
  '3xl': 'p-4'
}

const sizeToTextStyle: Record<SizeType, string> = {
  xs: 'text-xs',
  s: 'text-[.8125rem]',
  m: 'text-base',
  l: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl'
}

const sizeToMarkerStyle: Record<SizeType, string> = {
  xs: 'w-[6px] h-[6px]',
  s: 'w-[8px] h-[8px]',
  m: 'w-[10px] h-[10px]',
  l: 'w-[12px] h-[12px]',
  xl: 'w-[14px] h-[14px]',
  '2xl': 'w-[16px] h-[16px]',
  '3xl': 'w-[2Opx] h-[20px]'
}

export type AvatarProps = Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'size'> & {
  url?: any // FIXME any
  size?: SizeType
  fullName?: string
  marked?: boolean
  showTooltip?: boolean
}

/**
 * @group Components
 * @category Component
 */
export const Avatar = forwardRef(
  (
    { className, url, onClick, marked, fullName, size = 's', disabled, showTooltip = true, ...restProps }: AvatarProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const src = url || (defaultImage as unknown as string)
    const sizeStyle = sizeToStyle[size]
    const textStyle = sizeToTextStyle[size]
    const markerStyle = sizeToMarkerStyle[size]
    const sizeToPaddingStyle = sizeToPadding[size]

    return (
      <div className="relative flex items-center">
        <Tooltip dark label={fullName || ''} side="top" sideOffset={5} disabled={!fullName || disabled || !showTooltip}>
          <div
            onClick={onClick}
            ref={ref}
            {...restProps}
            className={classnames(
              'relative rounded-full bg-grayNeutral-100 overflow-hidden cursor-default',
              onClick && 'cursor-pointer active:border-2 active:border-primary',
              sizeStyle,
              !url && sizeToPaddingStyle,
              className
            )}
          >
            {url ? (
              <img src={src} className={'object-cover'} />
            ) : fullName ? (
              <p
                className={classnames(
                  textStyle,
                  'text-grayNeutral-500 font-semibold w-full h-full flex items-center justify-center'
                )}
              >
                {getInitials(fullName)}
              </p>
            ) : (
              <img src={src} className={'bg-cover'} />
            )}
          </div>
          {marked && (
            <div
              className={classnames(
                markerStyle,
                'absolute bg-positive rounded-full right-0 bottom-0 border-white border-[1px]'
              )}
            />
          )}
        </Tooltip>
      </div>
    )
  }
)
