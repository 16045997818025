import { PropsWithChildren, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useOrientation } from 'react-use'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { StatusBar } from '@capacitor/status-bar'
import { Dialog, DialogPanel } from '@headlessui/react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { Tablet01 } from '@untitled-ui/icons-react'

import { isMobile, isNative } from '@app/utils/platformUtils'

import './style.css'

export const OrientationLockProvider = ({ children }: PropsWithChildren) => {
  const state = useOrientation()
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const { pathname } = useLocation()
  const isUnityGame = pathname.includes('unity-game')

  useEffect(() => {
    // if the device is mobile and the orientation is landscape, show the dialog
    if (isMobile() && state.type === 'landscape-primary' && !isUnityGame) {
      setIsDialogVisible(true)
    } else {
      setIsDialogVisible(false)
    }
  }, [state.type, pathname])

  useEffect(() => {
    if (isMobile() && isNative()) {
      ScreenOrientation.lock({ orientation: 'portrait-primary' })
      StatusBar.setBackgroundColor({ color: '#ffffff' })
      StatusBar.setOverlaysWebView({ overlay: true })
    }
  }, [])

  return (
    <>
      {isDialogVisible && (
        <Dialog static open={isDialogVisible} className="relative z-[1002]" onClose={() => {}}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-neutral800/80"
          />
          <div className="fixed inset-0 flex w-screen items-center justify-center px-2">
            <DialogPanel
              as={motion.div}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="max-w-[400px] rounded-3xl"
            >
              <Tablet01 width={80} height={80} className={classNames('animate-spin-mobile', 'text-white0')} />
            </DialogPanel>
          </div>
        </Dialog>
      )}

      {children}
    </>
  )
}
