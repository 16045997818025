import { useEffect } from 'react'
import { useWindowSize } from 'react-use'
import dayjs from 'dayjs'
import { motion, MotionValue, useMotionValue, useTransform } from 'framer-motion'

import { ImageContent } from '@app/assets/ImageContent'
import { SvgContent } from '@app/assets/SvgContent'
import { LoaderWrapper } from '@app/components'
import { ActivityResult, FlashCardAnswer, LearningAssignmentType } from '@app/data'
import { useTranslation } from '@app/locales'
import { isNative } from '@app/utils/platformUtils'

import { FinishedTaskItem } from '../components'
import { useCompletedLearningExecution } from '../data'
import { LearningExecutionType } from '../model/types'

type Props = {
  task: LearningExecutionType
  type: string
}

const TaskDetailPage = ({ task, type }: Props) => {
  const { t } = useTranslation('classroom')

  const { completedLearningExecution, loading, error } = useCompletedLearningExecution(task.id)
  const finishedTask = completedLearningExecution?.learningExecution || task
  const answers = completedLearningExecution?.answers || []
  const totalSkippedCount = completedLearningExecution?.totalSkippedCount || 0
  const assignmentType = task.learningAssignment.type

  const successfulLearningFeatureCount = answers
    .flatMap((item) => item.userAnswers)
    .filter((item) => item.result === ActivityResult.CORRECT || item.result === ActivityResult.ALMOST_CORRECT).length
  const learningFeatureCount = answers.flatMap((item) => item.userAnswers).length
  const result = finishedTask?.successMeasure || 0

  const { width: windowWidth } = useWindowSize()
  const widthValue = useMotionValue(windowWidth)
  const top = useTransform(widthValue, [391, 769], [216, 296])
  const height = useTransform(widthValue, [391, 769], [286, 366])

  useEffect(() => {
    widthValue.set(windowWidth)
  }, [windowWidth])

  return (
    <div className="relative flex h-full w-full flex-col pb-safe">
      <CardBg assignmentType={assignmentType} type={type} height={height} />

      <motion.div
        style={{
          top,
          boxShadow: '0px -80px 90px 25px rgba(44, 0, 119, 0.60), 0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset'
        }}
        className="absolute z-20 flex h-auto min-h-[80vh] w-full flex-col items-center gap-8 rounded-t-3xl bg-[#5A23BA] p-4 md:p-8"
      >
        <div className="absolute -top-[84px] z-40 flex w-full flex-col items-center justify-center">
          <div className="line-clamp-1 text-center text-4xl font-extrabold text-neutral50">
            {finishedTask?.name ||
              (assignmentType ? t(`learningAssignmentType.${assignmentType.toLocaleLowerCase()}`).toUpperCase() : '')}
          </div>
          <div className="flex flex-row items-center justify-between gap-1 font-[600] text-neutral50">
            <object className="h-6 w-6 object-contain" type="image/svg+xml" data={SvgContent.library.clockFilled} />
            {t('completedIn') + dayjs.duration(completedLearningExecution?.studyTimeSeconds || 0, 'seconds').humanize()}
          </div>
        </div>

        <div className="flex w-full flex-col">
          <div className="flex w-full flex-row items-center justify-between gap-2 pb-0">
            {/* descriptions */}
            <div className="flex w-full flex-col gap-2">
              <div className="flex flex-row items-center gap-2 font-[600] text-neutral50">
                <object className="h-6 w-6 object-contain" type="image/svg+xml" data={SvgContent.library.bookmark} />
                {assignmentType ? t(`learningAssignmentType.${assignmentType.toLocaleLowerCase()}`) : ''}
              </div>
            </div>
          </div>

          <div
            style={{
              border: '1px solid rgba(255, 255, 255, 0.50)',
              background:
                'radial-gradient(271.98% 55.21% at 50% 11.46%, rgba(161, 98, 255, 0.40) 0%, rgba(96, 46, 202, 0.40) 100%)',
              boxShadow: '2px 2px 12px 0px rgba(35, 35, 35, 0.20)'
            }}
            className="relative mt-4 flex min-h-20 w-full flex-row items-center justify-between rounded-2xl p-2 md:px-8"
          >
            <div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-1">
              <img
                className="h-10 w-10 rounded-full"
                src={
                  result >= 90
                    ? ImageContent.library.starGold
                    : result >= 70
                      ? ImageContent.library.starSilver
                      : ImageContent.library.starBronze
                }
                alt="favoriteEclipse"
              />
              <span className="text-center text-base text-neutral200">{t('successRate')}</span>
            </div>
            <div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-1">
              <div className="flex h-10 items-center">
                <span className={`text-lg font-extrabold ${result >= 50 ? 'text-[#81FF6C]' : 'text-neutral50'}`}>
                  {successfulLearningFeatureCount}/{learningFeatureCount}
                </span>
              </div>

              <span className="text-base text-neutral200">{t('questions')}</span>
            </div>
            <div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-1">
              <div className="flex h-10 items-center">
                <span className="text-lg font-extrabold text-neutral50">{totalSkippedCount}</span>
              </div>
              <span className="text-base text-neutral200">{t('skipped')}</span>
            </div>
          </div>
        </div>
        <LoaderWrapper loading={loading} error={!!error}>
          {/* list of answers */}
          <div className="mt-2 flex h-full w-full flex-col gap-4">
            <div className="grid w-full grid-cols-1 gap-3 md:grid-cols-2 xl:grid-cols-3">
              {answers.map((answer, index) => {
                return <FinishedTaskItem key={index} item={answer as FlashCardAnswer} />
              })}
            </div>
          </div>

          {/* NOTE: this a workaround for overflow-hidden style in the body element */}
          {isNative() ? <div className="min-h-16 w-full" /> : <div className="min-h-32 w-full" />}
        </LoaderWrapper>
      </motion.div>

      <div className="fixed bottom-0 z-[100] flex w-full items-center justify-center bg-gradient-to-b from-[#9069D800] to-[#7745c9] py-9" />
    </div>
  )
}

export default TaskDetailPage

const CardBg = ({
  assignmentType,
  type,
  height
}: {
  assignmentType?: LearningAssignmentType
  type?: string
  height: MotionValue<number>
}) => {
  const bgImage1Src =
    assignmentType === LearningAssignmentType.TEST
      ? ImageContent.tasks.testBg1
      : assignmentType === LearningAssignmentType.HOMEWORK
        ? ImageContent.tasks.homeworkBg1
        : assignmentType === LearningAssignmentType.REPETITION ||
            assignmentType === LearningAssignmentType.PERSONALIZED_REPETITION
          ? ImageContent.tasks.repetitionBg1
          : ImageContent.tasks.selfStudyBg1

  const bgImage2Src =
    assignmentType === LearningAssignmentType.TEST
      ? ImageContent.tasks.testBg1 // TODO design missing
      : assignmentType === LearningAssignmentType.HOMEWORK
        ? ImageContent.tasks.homeworkBg2
        : assignmentType === LearningAssignmentType.REPETITION ||
            assignmentType === LearningAssignmentType.PERSONALIZED_REPETITION
          ? ImageContent.tasks.repetitionBg1 // TODO design missing
          : ImageContent.tasks.selfStudyBg1 // TODO design missing

  if (!assignmentType) {
    return null
  }

  return (
    <motion.div style={{ height }} className="absolute top-0 z-20 w-full">
      <img
        src={type === 'even' ? bgImage1Src : bgImage2Src}
        className="absolute top-0 h-full w-full object-cover object-center"
      />
    </motion.div>
  )
}
