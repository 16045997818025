export const capitalizeFirstLetter = (inputString: string) => {
  return inputString.length >= 2
    ? inputString.charAt(0).toUpperCase() + inputString.slice(1)
    : inputString.charAt(0).toUpperCase()
}

export const toLowerCaseFirstLetter = (inputString: string) => {
  return inputString.length >= 2
    ? inputString.charAt(0).toLowerCase() + inputString.slice(1)
    : inputString.charAt(0).toLowerCase()
}

export const removePunctuation = (inputString: string) => {
  return inputString.replace('.', '').toLocaleLowerCase()
}
