import { Dialog, DialogPanel } from '@headlessui/react'
import { motion } from 'framer-motion'
import { RefreshCcw01 } from '@untitled-ui/icons-react'

import { Button } from '@app/components'
import { useRepeatLearningExecutionMutation } from '@app/data'
import { useTranslation } from '@app/locales'

import { LearningExecutionType } from '../model/types'

type Props = {
  onClose: () => void
  onConfirm: (execution: LearningExecutionType) => void
  execution: unknown
}

export const ConfirmActivityRepeatDialog = ({ onClose, onConfirm, execution }: Props) => {
  const { t } = useTranslation('dashboard')

  const [repeatLearningExecution] = useRepeatLearningExecutionMutation()

  const executionType = execution as LearningExecutionType

  const handleOnConfirmClick = async () => {
    await repeatLearningExecution({
      variables: { executionId: executionType.id }
    })

    onConfirm(executionType)
  }

  return (
    <Dialog static open={true} onClose={onClose} className="relative z-[1001] w-full overflow-hidden">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 backdrop-blur"
      />
      <div className="fixed inset-0 -top-16 flex w-screen items-center justify-center px-2">
        <DialogPanel
          as={motion.div}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          className="relative min-h-[342px] w-[274px] overflow-hidden"
        >
          <div className="flex h-full w-full flex-col">
            <div
              style={{ backgroundImage: 'linear-gradient(180deg, #C3BDFF 0%, #F5F4FF 100%)' }}
              className="absolute bottom-0 z-20 flex h-full w-full flex-col items-center justify-between gap-2 rounded-3xl p-4"
            >
              <RefreshCcw01 className="mt-4 size-12 text-[#8336E6]" />

              <div className="text-center text-xl font-semibold text-black">{t('repeatActivity.title')}</div>

              <div className="flex w-full flex-col gap-2">
                <Button
                  className="w-full"
                  btnClassName="bg-skip-gradient hover:bg-skip-hover-gradient shadow-skip text-neutral50"
                  onClick={handleOnConfirmClick}
                >
                  {t('repeatActivity.ok')}
                </Button>

                <Button className="w-full" mode="outline" onClick={onClose}>
                  {t('repeatActivity.cancel')}
                </Button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
}
