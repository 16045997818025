import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useToggle } from 'react-use'
import { AnimatePresence } from 'framer-motion'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useVisibleState } from '@foxino/components-common'

import { LoaderWrapper } from '@app/components'
import { DragDrawer } from '@app/components/DragDrawer'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'
import { MainHeader } from '@app/modules/common'
import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'
import { useProfileSettings } from '@app/modules/settings/data'
import { isCurrentPathActive } from '@app/utils/commonUtils'

import { InputSearch } from '../../components/InputSearch'
import { NoPackageSection } from '../../components/NoPackageSection'
import { StartSubPackageDialog } from '../../components/StartSubPackageDialog'
import { SubPackage } from '../../components/SubPackage'
import { useStudentLibraryOverview } from '../../data/useStudentLibraryOverview'
import { PackageDetailPage } from '../PackageDetailPage'

export const LibraryPage = () => {
  const { t } = useTranslation('library')
  const { pathname, search } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const lastSelectedRef = useRef<HTMLDivElement>(null)
  const packageId = searchParams.get('packageId')

  const [searchQuery, setSearchQuery] = useState('')

  const [isSwitchClassBannerVisible, toggleSwitchClassBanner] = useToggle(false)
  const [isDetailDialogVisible, toggleDetailDialogVisible] = useToggle(false)
  const [isStartPackageDialogVisible, showStartPackageDialog, closeStartPackageDialog] = useVisibleState<string>()

  const classroomId = localSettingsStore.useStore((state) => state.classroomId)

  const {
    studentProfile,
    // error: profileError,
    loading: profileLoading,
    refetching: profileRefetching
  } = useProfileSettings()
  const { classrooms } = studentProfile || {}

  const currentClassroom = classrooms?.find((classroom) => classroom.id === classroomId)

  const {
    overview,
    // error: overviewError,
    loading: overviewLoading,
    refetching: overviewRefetching,
    refetch: refetchOverview
  } = useStudentLibraryOverview({ skip: !currentClassroom?.subject, variables: { subject: currentClassroom?.subject } })

  useEffect(() => {
    if (currentClassroom) {
      refetchOverview()
    }
  }, [classroomId])

  useEffect(() => {
    if (isCurrentPathActive(pathname, Pages.DASHBOARD_LIBRARY, true) && !!overview) {
      refetchOverview()
    }
  }, [pathname])

  const handleSearch = (query) => {
    setSearchQuery(query)
  }

  const handlePackageClick = (subPackageId?: string) => {
    if (subPackageId) {
      showStartPackageDialog(subPackageId)
      const params = new URLSearchParams(search)
      params.set('packageId', subPackageId)
      navigate({ search: params.toString() })
    }
  }

  const loading = profileLoading || profileRefetching || overviewLoading || overviewRefetching

  const progressPackages =
    overview && overview.inProgress && overview.inProgress.length > 0
      ? overview.inProgress.map((item) => ({ ...item }))
      : []
  const progressPackagesFiltered = searchQuery
    ? progressPackages.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
    : progressPackages

  const otherPackages =
    overview && overview.packages && overview.packages.length > 0 ? overview.packages.map((item) => ({ ...item })) : []
  const otherPackagesFiltered = searchQuery
    ? otherPackages
        .map((pkg) => ({
          ...pkg,
          // for each package, filter its subPackages array down to just those subPackages whose names match searchQuery.
          subPackages: pkg.subPackages.filter((subPkg) =>
            subPkg?.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
        }))
        // remove any packages that end up with no matching subPackages
        .filter((pkg) => pkg.subPackages.length > 0)
    : otherPackages

  useEffect(() => {
    if (packageId) {
      const element = lastSelectedRef.current

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [packageId])

  return (
    <div className="relative flex h-full w-full flex-col overflow-y-auto overscroll-y-contain bg-[#5A23BA] pb-40 pt-safe">
      <AnimatePresence>
        {isStartPackageDialogVisible && packageId && (
          <StartSubPackageDialog
            subPackageId={packageId}
            isDialogVisible={isStartPackageDialogVisible}
            onClose={closeStartPackageDialog}
            openDetail={toggleDetailDialogVisible}
          />
        )}
      </AnimatePresence>

      <DragDrawer isOpen={isDetailDialogVisible} closeDialog={toggleDetailDialogVisible} className="">
        {packageId && <PackageDetailPage id={packageId} />}
      </DragDrawer>

      <MainHeader
        title={t('title')}
        isSwitchClassEnable
        isSwitchClassBannerVisible={isSwitchClassBannerVisible}
        toggleSwitchClassBanner={toggleSwitchClassBanner}
      />

      {isSwitchClassBannerVisible && <div className="fixed inset-0 z-[98] bg-black/50 backdrop-blur top-safe" />}

      <div className="flex h-auto w-full flex-col items-center justify-start pt-16 md:pt-20">
        <div className="mb-4 w-full px-4 md:px-8">
          <InputSearch onSearch={handleSearch} />
        </div>

        {!loading && progressPackagesFiltered.length === 0 && otherPackagesFiltered.length === 0 && (
          <NoPackageSection />
        )}

        <LoaderWrapper loading={loading} showChildrenOnLoading={!!overview}>
          {progressPackagesFiltered.length > 0 && (
            <div className="flex w-full flex-col gap-2">
              <div className="flex w-full flex-row items-center justify-start pl-4 md:pl-8">
                <span className="text-lg font-semibold text-white">{t('inProgress')}</span>
              </div>

              <Swiper
                className="mb-4 w-full pr-4"
                modules={[FreeMode]}
                freeMode={true}
                spaceBetween={12}
                slidesPerView="auto"
              >
                {progressPackagesFiltered.map((subP) => {
                  return (
                    <SwiperSlide key={subP?.id} className="w-auto first-of-type:pl-4 md:first-of-type:pl-8">
                      <SubPackage data={subP} onClick={() => handlePackageClick(subP.id)} />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          )}

          {otherPackagesFiltered.map((p, index) => {
            return (
              <div key={index} className="flex w-full flex-col gap-2">
                <div className="flex w-full flex-row items-center justify-start pl-4 md:pl-8">
                  <span className="text-lg font-semibold text-neutral50">{p.name}</span>
                </div>

                <Swiper
                  className="mb-4 w-full pr-4"
                  modules={[FreeMode]}
                  freeMode={true}
                  spaceBetween={12}
                  slidesPerView="auto"
                >
                  {p.subPackages.map((subP) => {
                    return (
                      <SwiperSlide key={subP?.id} className="w-auto first-of-type:pl-4 md:first-of-type:pl-8">
                        <div className="w-auto" ref={subP?.id === packageId ? lastSelectedRef : undefined}>
                          <SubPackage data={subP} onClick={() => handlePackageClick(subP?.id)} />
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            )
          })}
        </LoaderWrapper>
      </div>
    </div>
  )
}
