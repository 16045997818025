import { useNavigate } from 'react-router-dom'

import { Pages } from '@app/config/router/Pages'
import {
  InteractionType,
  LearningAssignmentType,
  Skill,
  Subject,
  useStartStudentLibrarySubPackageMutation
} from '@app/data'
import { useMicrophonePermission } from '@app/hooks'
import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'
import { lessonInputsStore } from '@app/modules/lesson/store'
import { useProfileSettings } from '@app/modules/settings/data'
import { hapticsImpactMedium } from '@app/utils/hapticsUtils'
import { logError } from '@app/utils/logsUtils'

import { SubPackageDetail, SubPackageDetailLevel } from '../model/types'

interface UseStartPackageHandlerParams {
  id?: string
  currentDetail?: SubPackageDetail
  currentLevel?: SubPackageDetailLevel
}

export const useStartPackageHandler = ({ id, currentLevel, currentDetail }: UseStartPackageHandlerParams) => {
  const navigate = useNavigate()

  const { permissionState, requestMicrophone, onToggleInteractionsValue, canSpeak, isRequesting } =
    useMicrophonePermission()
  const { studentProfile } = useProfileSettings()
  const { classrooms } = studentProfile || {}
  const classroomId = localSettingsStore.useStore((state) => state.classroomId)
  const selectedClassroom = classrooms?.find((classroom) => classroom.id === classroomId)
  const selectedSubject = selectedClassroom ? selectedClassroom.subject : Subject.ENGLISH // just placeholder

  const [startPackage] = useStartStudentLibrarySubPackageMutation()

  const handleStartPackage = async () => {
    try {
      if (!currentDetail || !currentLevel) {
        return
      }

      let canSpeakFinal = canSpeak

      if (permissionState !== 'granted' && canSpeakFinal) {
        const result = await requestMicrophone()
        if (result !== 'granted') {
          canSpeakFinal = false
          onToggleInteractionsValue(InteractionType.SPEAKING, false)
        } else {
          canSpeakFinal = true
        }
      } else {
        onToggleInteractionsValue(InteractionType.SPEAKING, true)
        canSpeakFinal = true
      }

      const result = await startPackage({
        variables: {
          subPackageId: id || '',
          level: currentLevel.level
        }
      })

      if (result?.data?.startStudentLibrarySubPackage) {
        lessonInputsStore.setNewLearningAssignment({
          learningExecutionId: result?.data?.startStudentLibrarySubPackage,
          assignmentType: LearningAssignmentType.STUDENT_LIBRARY_EXERCISE,
          skills: [Skill.READING, Skill.WRITING],
          canSpeak: canSpeakFinal,
          defaultName: currentDetail.name,
          subject: selectedSubject,
          previousRouter: Pages.DASHBOARD_LIBRARY
        })

        hapticsImpactMedium()

        navigate(Pages.LESSON_TRAINING)
      }
    } catch (error) {
      logError(error, 'useStartPackageHandler', 'handleStartPackage')
    }
  }

  return { handleStartPackage, isRequesting }
}
