import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Tool01 } from '@untitled-ui/icons-react'

import { Heading } from '@app/components'
import { Pages } from '@app/config/router/Pages'

import { useCheckMaintenance } from '../../data/useCheckMaintenance'

import './style.css'

const CHECK_INTERVAL = 20_000

export const MaintenancePage = () => {
  const { isMaintenance, checkMaintenanceStatus } = useCheckMaintenance()
  const navigate = useNavigate()

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    if (isMaintenance === false) {
      navigate(Pages.DASHBOARD)
    } else if (isMaintenance) {
      intervalId = setInterval(() => {
        checkMaintenanceStatus()
      }, CHECK_INTERVAL)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isMaintenance])

  return (
    <div className="absolute flex h-screen w-full flex-col overscroll-none text-white0 pt-safe">
      <Heading className="mx-auto" title="Maintenance">
        Maintenance
      </Heading>
      <div className="flex h-screen w-screen">
        <div className="my-auto flex w-full flex-col items-center justify-center">
          <img
            src="/images/maintenance.webp"
            alt="Maintenance"
            className={classNames('mx-auto my-auto h-[50vh]', 'animate-maintenance-bounce')}
          />
          <Heading size="m" className="flex items-center gap-2">
            <Tool01 /> We&apos;ll be back soon...
          </Heading>
        </div>
      </div>
    </div>
  )
}
