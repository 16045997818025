import { ReactNode } from 'react'
import classNames from 'classnames'
import { AnimationProps, motion } from 'framer-motion'

import { isNative } from '@app/utils/platformUtils'

const pageVariants: AnimationProps['variants'] = {
  initial: {
    opacity: 0,
    x: '-100vw'
  },
  in: {
    opacity: 1,
    x: 0
  },
  out: {
    opacity: 0,
    x: '100vw'
  }
}

export const AnimatedLayout = ({ children, className }: { children: ReactNode; className?: string }) => {
  if (!isNative()) {
    return <>{children}</>
  }

  return (
    <motion.div
      className={classNames('h-full w-[inherit]', className)}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
    >
      {children}
    </motion.div>
  )
}
