import { ReactElement } from 'react'

import { colors } from '@foxino/configs/tailwind/colors'

import { hapticsImpactLight } from '@app/utils/hapticsUtils'

type ColorsType = 'primary' | 'colored'

type ColorsStyle = {
  circleBorder: string
  containerBackground: string
  containerBorder: string
}

type Props = {
  value?: boolean
  colorType?: ColorsType
  disabled?: boolean
  onValueChange: (value: boolean) => void
}

const getStyleFromColors = (colorsType: ColorsType): Record<'on' | 'off', ColorsStyle> => {
  const c = colors

  const colorsToStyle: Record<ColorsType, Record<'on' | 'off', ColorsStyle>> = {
    primary: {
      on: {
        circleBorder: c.primary500,
        containerBackground: c.primary500,
        containerBorder: c.primary500
      },
      off: {
        circleBorder: c.primary500,
        containerBackground: c.neutral100,
        containerBorder: c.neutral200
      }
    },
    colored: {
      on: {
        circleBorder: c.positive[500],
        containerBackground: c.positive[500],
        containerBorder: c.positive[500]
      },
      off: {
        circleBorder: c.danger500,
        containerBackground: c.danger500,
        containerBorder: c.danger500
      }
    }
  }

  return colorsToStyle[colorsType]
}

export const Switch = ({
  value = false,
  colorType = 'colored',
  onValueChange,
  disabled,
  ...restProps
}: Props): ReactElement => {
  const toggle = () => {
    hapticsImpactLight()
    onValueChange(!value)
  }

  const style = getStyleFromColors(colorType)

  return (
    <button onClick={toggle} disabled={disabled} {...restProps}>
      <div
        style={{
          width: 40,
          height: 40,
          position: 'relative',
          opacity: disabled ? 0.5 : 1
        }}
      >
        <div
          style={{
            width: 40,
            height: 12.38,
            backgroundColor: value ? style.on.containerBackground : style.off.containerBackground,
            borderWidth: 1,
            borderColor: value ? style.on.containerBorder : style.off.containerBorder,
            borderRadius: 10,
            top: 13.81,
            left: 0,
            position: 'absolute'
          }}
        />
        <div
          className="transition-all"
          style={{
            width: 22.86,
            height: 22.86,
            backgroundColor: colors.white0,
            borderColor: value ? style.on.circleBorder : style.off.circleBorder,
            borderWidth: 5,
            borderRadius: 20,
            position: 'absolute',
            left: value ? 17.14 : 0,
            top: 8.57
          }}
        />
      </div>
    </button>
  )
}
