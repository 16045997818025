import { ReactElement, ReactNode, useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

import { useTimeout } from '@foxino/components-common'

import { LoaderWrapper } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { LOGIN_CODE_PARAM } from '@app/modules/common/model/searchCodeParams'

import { authStore } from '../store/authStore'
import { localTokenStore } from '../store/localTokenStore'

type Props = {
  children: ReactNode | ReactNode[] | null
}

const LOGOUT_TIMER = 1000

export const AuthProvider = ({ children }: Props): ReactElement | null => {
  const { keycloak } = useKeycloak()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const loginCodeParam = searchParams.get(LOGIN_CODE_PARAM)

  const [callLogoutTimer, isWaiting, clearLogoutTimer] = useTimeout(LOGOUT_TIMER)

  const isLoggedWithQR = localTokenStore.useStore((store) => store.isLoggedWithQR)

  // Logout user when keycloak is initialized, but not authenticated
  // This is edge case where session needs to be cleared and logged in again
  useEffect(() => {
    if (!keycloak?.authenticated && !isLoggedWithQR) {
      // Allow login with QR code when heading to social activity page
      if (loginCodeParam && location.pathname === Pages.SOCIAL_ACTIVITY_LOGIN) {
        navigate(Pages.QR_LOGIN + '?' + searchParams.toString())
      } else if (isWaiting) {
        callLogoutTimer(() => {
          console.info('[AuthProvider] Logout user - not authenticated')

          authStore.clearStore()
          navigate('/', { replace: true })
        })
      }
    } else {
      clearLogoutTimer()
    }
  }, [isWaiting, keycloak?.authenticated])

  if (!keycloak?.authenticated && !isLoggedWithQR) {
    return (
      <div className="h-[100vh] w-full">
        <LoaderWrapper loading className="animate-spin" />
      </div>
    )
  }

  return <>{children}</>
}
