import { useEffect, useState } from 'react'
import { useToggle } from 'react-use'
import { ChevronDown } from '@untitled-ui/icons-react'

import { Checkbox } from '@foxino/components-web'

import { Button, Text } from '@app/components'
import Constants from '@app/constants'
import { FlashCardLexicalType } from '@app/data'
import { flashcardCombinations } from '@app/modules/lesson/model/constants'
import {
  getAllFlashCardLexicalTypes,
  getSupportedFlashCardAssignmentTypes,
  getSupportedFlashCardResponseTypes,
  getSupportedFullscreenFlashCardTypes
} from '@app/modules/lesson/utils/getSupportedFlashCardTypes'

export const FlashcardsSelection = () => {
  const allLexicalTypes = getAllFlashCardLexicalTypes()

  const [flashCardLexicalTypes, seFlashCardLexicalTypes] = useState<FlashCardLexicalType[]>(
    Constants.ALL_FLASHCARD_LEXICAL_TYPES
  )

  const [selectedTypes, setSelectedTypes] = useState<(keyof typeof flashcardCombinations)[]>(
    Object.keys(flashcardCombinations)
      .filter((item) => {
        const type = item as keyof typeof flashcardCombinations
        const { assignmentType, fullscreenType, responseType } = flashcardCombinations[type]

        return (
          (fullscreenType && Constants.SUPPORTED_FLASHCARD_FULLSCREEN_TYPES.includes(fullscreenType)) ||
          (assignmentType &&
            Constants.SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES.includes(assignmentType) &&
            responseType &&
            Constants.SUPPORTED_FLASHCARD_RESPONSE_TYPES.includes(responseType))
        )
      })
      .map((flashcardType) => {
        return flashcardType as keyof typeof flashcardCombinations
      })
  )

  const [isVisible, toggleVisible] = useToggle(false)

  const handleOnSwitch = (type: keyof typeof flashcardCombinations, event: any) => {
    const value = event.target.checked
    setSelectedTypes((prevState) => {
      if (!prevState) {
        return value ? [type] : []
      }

      return value
        ? prevState.includes(type)
          ? prevState
          : [...prevState, type]
        : prevState.includes(type)
          ? prevState.filter((t) => t !== type)
          : prevState
    })
  }

  const handleOnLexicalTypeSwitch = (type: FlashCardLexicalType, event: any) => {
    const value = event.target.checked
    seFlashCardLexicalTypes((prevState) => {
      return value
        ? prevState.includes(type)
          ? prevState
          : [...prevState, type]
        : prevState.includes(type)
          ? prevState.filter((t) => t !== type)
          : prevState
    })
  }

  const fullScreenTypes = getSupportedFullscreenFlashCardTypes(flashCardLexicalTypes)
  const assignmentTypes = getSupportedFlashCardAssignmentTypes(flashCardLexicalTypes)
  const responseTypes = getSupportedFlashCardResponseTypes(flashCardLexicalTypes)

  useEffect(() => {
    return () => {
      Constants.ALL_FLASHCARD_LEXICAL_TYPES = flashCardLexicalTypes
      Constants.SUPPORTED_FLASHCARD_FULLSCREEN_TYPES = fullScreenTypes.filter((type) =>
        selectedTypes.find((item) => {
          const { fullscreenType } = flashcardCombinations[item]
          return type === fullscreenType
        })
      )
      Constants.SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES = assignmentTypes.filter((type) =>
        selectedTypes.find((item) => {
          const { assignmentType } = flashcardCombinations[item]
          return type === assignmentType
        })
      )
      Constants.SUPPORTED_FLASHCARD_RESPONSE_TYPES = responseTypes.filter((type) =>
        selectedTypes.find((item) => {
          const { responseType } = flashcardCombinations[item]
          return type === responseType
        })
      )
    }
  }, [flashCardLexicalTypes, selectedTypes])

  return (
    <div className="max-h-72 w-full max-w-[400px] overflow-y-auto rounded-lg bg-white">
      <div
        className="flex cursor-pointer items-center justify-between rounded-lg bg-btn-primary-outline-bg-gradient px-4 py-1"
        onClick={toggleVisible}
      >
        <h2>Flashcard type selection</h2>
        <ChevronDown />
      </div>

      {isVisible && (
        <div className="flex flex-col rounded-lg px-4">
          <Button
            onClick={() => {
              Constants.clearAllAsyncValues(false)
              toggleVisible()
            }}
          >
            Reset settings
          </Button>

          <Text>Lexical types</Text>
          {allLexicalTypes.map((item) => {
            return (
              <div key={item} className="flex items-center gap-2 py-1">
                <Checkbox
                  size="big"
                  checked={flashCardLexicalTypes.includes(item)}
                  onChange={(event) => handleOnLexicalTypeSwitch(item, event)}
                />
                <Text>{item}</Text>
              </div>
            )
          })}

          <Text>Flashcards</Text>
          {Object.keys(flashcardCombinations)
            .filter((item) => {
              const type = item as keyof typeof flashcardCombinations
              const { assignmentType, fullscreenType, responseType } = flashcardCombinations[type]

              return (
                (fullscreenType && fullScreenTypes.includes(fullscreenType)) ||
                (assignmentType && assignmentTypes.includes(assignmentType)) ||
                (responseType && responseTypes.includes(responseType))
              )
            })
            .map((flashcardType) => {
              const type = flashcardType as keyof typeof flashcardCombinations
              const checked = selectedTypes?.includes(type)

              return (
                <div key={flashcardType} className="flex items-center gap-2 py-1">
                  <Checkbox size="big" checked={checked} onChange={(event) => handleOnSwitch(type, event)} />
                  <Text>{flashcardType}</Text>
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}
