import {
  FlashCardAssignmentType,
  FlashCardLexicalType,
  FlashCardResponseType,
  FullScreenFlashCardType
} from '@app/data'

export const getAllFlashCardLexicalTypes = (): FlashCardLexicalType[] => {
  return [
    FlashCardLexicalType.TRANSLATION,
    FlashCardLexicalType.SYNONYM,
    FlashCardLexicalType.ANTONYM,
    FlashCardLexicalType.DEFINITION
  ]
}

/**
 * Returns the supported fullscreen flashcard types for the given lexical type
 */
export const getSupportedFullscreenFlashCardTypes = (
  lexicalTypes: FlashCardLexicalType[]
): FullScreenFlashCardType[] => {
  if (lexicalTypes.includes(FlashCardLexicalType.TRANSLATION)) {
    return [
      // FullScreenFlashCardType.SWIPE_MATCH,
      FullScreenFlashCardType.TEXT_TO_IMAGE_CONNECTION,
      FullScreenFlashCardType.TEXT_TO_TEXT_CONNECTION,
      FullScreenFlashCardType.SOUND_TO_TEXT_CONNECTION,
      FullScreenFlashCardType.WHEEL_OF_FORTUNE_IMAGE,
      FullScreenFlashCardType.WHEEL_OF_FORTUNE_TEXT,
      ...(import.meta.env.VITE_ENVIRONMENT === 'development' ? [FullScreenFlashCardType.BALLOONS] : [])

      /** unsupported types */
      // FlashCardType.SHOPPING_CART
    ]
  }

  if (lexicalTypes.includes(FlashCardLexicalType.SYNONYM)) {
    // TODO add other types
    return [FullScreenFlashCardType.BALLOONS]
  }

  if (lexicalTypes.includes(FlashCardLexicalType.ANTONYM)) {
    // TODO add other types
    return [FullScreenFlashCardType.BALLOONS]
  }

  if (lexicalTypes.includes(FlashCardLexicalType.DEFINITION)) {
    // TODO add other types
    return [FullScreenFlashCardType.BALLOONS]
  }

  return []
}

/**
 * Returns the supported assignment types for the given lexical type
 */
export const getSupportedFlashCardAssignmentTypes = (
  lexicalTypes: FlashCardLexicalType[]
): FlashCardAssignmentType[] => {
  if (lexicalTypes.includes(FlashCardLexicalType.TRANSLATION)) {
    return [
      FlashCardAssignmentType.IMAGE,
      // FlashCardAssignmentType.SOUND, // this type should not be used anymore
      FlashCardAssignmentType.TEXT,
      FlashCardAssignmentType.VIDEO
    ]
  }

  if (lexicalTypes.includes(FlashCardLexicalType.SYNONYM)) {
    // TODO add other types
    return []
  }

  if (lexicalTypes.includes(FlashCardLexicalType.ANTONYM)) {
    // TODO add other types
    return []
  }

  if (lexicalTypes.includes(FlashCardLexicalType.DEFINITION)) {
    // TODO add other types
    return []
  }

  return []
}

/**
 * Returns the supported response types for the given lexical type
 */
export const getSupportedFlashCardResponseTypes = (lexicalTypes: FlashCardLexicalType[]): FlashCardResponseType[] => {
  if (lexicalTypes.includes(FlashCardLexicalType.TRANSLATION)) {
    return [
      FlashCardResponseType.CHOICE_IMAGE,
      FlashCardResponseType.CHOICE_TEXT,
      FlashCardResponseType.SOUND,
      // FlashCardResponseType.TEXT, // this type should not be used anymore
      FlashCardResponseType.SCRABBLE,
      FlashCardResponseType.WORD_PICKING
    ]
  }

  if (lexicalTypes.includes(FlashCardLexicalType.SYNONYM)) {
    // TODO add other types
    return []
  }

  if (lexicalTypes.includes(FlashCardLexicalType.ANTONYM)) {
    // TODO add other types
    return []
  }

  if (lexicalTypes.includes(FlashCardLexicalType.DEFINITION)) {
    // TODO add other types
    return []
  }

  return []
}
