import { useEffect } from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

import { Button } from '@foxino/components-web'

import Constants from '@app/constants'
import { useTranslation } from '@app/locales'

export const ErrorElement = () => {
  const { t } = useTranslation('common')
  const error = useRouteError()

  let errorMessage: string
  if (isRouteErrorResponse(error)) {
    errorMessage = error.data.message || error.statusText
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else if (typeof error === 'string') {
    errorMessage = error
  } else {
    console.error(error)
    errorMessage = 'Unknown error'
  }

  const handleOnRefreshClick = () => {
    window.location.href = '/'
  }

  useEffect(() => {
    console.error('[ErrorElement] error', error)

    if (Constants.ENVIRONMENT !== 'development') {
      handleOnRefreshClick()
    }
  }, [])

  return (
    <div id="error-page" className="flex h-full flex-col items-center justify-center gap-8">
      <h1 className="text-4xl font-bold">Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p className="text-slate-400">
        <i>{errorMessage}</i>
      </p>
      <div>
        <Button className="h-min" onClick={handleOnRefreshClick}>
          {t('back')}
        </Button>
      </div>
    </div>
  )
}
