import { PropsWithChildren, useState } from 'react'
import { useMeasure } from 'react-use'
import classNames from 'classnames'
import { motion, useAnimate, useDragControls, useMotionValue } from 'framer-motion'

type Props = {
  isOpen: boolean
  closeDialog: () => void
  className?: string
  touchAreaClassName?: string
} & PropsWithChildren

export const DragDrawer = ({ children, isOpen, className, touchAreaClassName, closeDialog }: Props) => {
  const controls = useDragControls()
  const y = useMotionValue(0)
  const [measureRef, { height }] = useMeasure<any>()
  const [scope, animate] = useAnimate()
  const [isScrollOnTop, setIsScrollOnTop] = useState(true)

  const handleClose = async () => {
    animate(scope.current, {
      opacity: [1, 0],
      transitions: { duration: 1 }
    })

    const yStart = typeof y.get() === 'number' ? y.get() : 0

    await animate('#drawer', {
      y: [yStart, height]
    })
    closeDialog()
  }

  return (
    <>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={handleClose}
          ref={scope}
          className="fixed inset-0 z-[1003] w-full bg-black bg-opacity-50 bottom-safe"
        >
          <motion.div
            id="drawer"
            ref={measureRef}
            onClick={(e) => e.stopPropagation()}
            initial={{ y: '100%', translateX: '-50%' }}
            animate={{ y: '0%' }}
            drag="y"
            dragControls={controls}
            dragListener={false}
            dragConstraints={{ top: 0, bottom: 0 }}
            dragElastic={{ top: 0, bottom: 0.8 }}
            onDragEnd={() => {
              if (y.get() > height / 5) {
                handleClose()
              }
            }}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
            style={{ y }}
            className={classNames(
              'lf:w-fit absolute bottom-0 left-[50%] h-full max-h-[90vh] w-full min-w-[50vw] max-w-[1312px] place-self-center overflow-hidden rounded-t-3xl bg-main-bg-gradient text-neutral50',
              className
            )}
          >
            <div className="absolute left-0 right-0 top-0 z-10 flex justify-center">
              <div
                className={classNames(
                  'flex w-full touch-none justify-center p-2',
                  isScrollOnTop ? 'h-[30vh]' : 'h-[5vh]',
                  touchAreaClassName
                )}
                onPointerDown={(e) => {
                  e.stopPropagation()
                  controls.start(e)
                }}
              >
                <button
                  onPointerDown={(e) => {
                    e.stopPropagation()
                    controls.start(e)
                  }}
                  className="mx-auto h-1 w-20 cursor-grab touch-none rounded-full bg-neutral50"
                />
              </div>
            </div>
            <div
              onScroll={(e) => {
                setIsScrollOnTop(e.currentTarget.scrollTop === 0)
              }}
              className="relative z-0 h-screen min-h-screen overflow-hidden overflow-y-auto overscroll-none"
            >
              {children}
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  )
}
