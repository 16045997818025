import { SvgContent } from '@app/assets/SvgContent'

interface ProgressCircleProps {
  progress: number
}

export const ProgressCircle = ({ progress }: ProgressCircleProps) => {
  const getProgressSvg = (progress: number) => {
    if (progress >= 90) return SvgContent.library.progress100
    if (progress >= 80) return SvgContent.library.progress90
    if (progress >= 70) return SvgContent.library.progress80
    if (progress >= 60) return SvgContent.library.progress70
    if (progress >= 50) return SvgContent.library.progress60
    if (progress >= 40) return SvgContent.library.progress50
    if (progress >= 30) return SvgContent.library.progress40
    if (progress >= 20) return SvgContent.library.progress30
    if (progress >= 10) return SvgContent.library.progress20
    return SvgContent.library.progress10
  }

  return (
    <div className="absolute -right-[2px] top-0 flex h-12 w-14 p-1">
      <img className="h-full w-full object-contain" alt="progress" src={getProgressSvg(progress)} />
    </div>
  )
}
