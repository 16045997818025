import { useEffect, useRef, useState } from 'react'

const DEFAULT_TIMEOUT = 1000

/**
 * Call timeout hook with clear function
 * @param duration Default 1000 ms
 * @returns
 */
export const useTimeout = (duration: number = DEFAULT_TIMEOUT) => {
  const [waiting, setWaiting] = useState<boolean>(true)
  const timer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const callTimeout = (callback?: () => void, localDuration?: number): Promise<void> => {
    return new Promise((resolve) => {
      setWaiting(false)
      timer.current = setTimeout(() => {
        setWaiting(true)
        callback?.()
        resolve(undefined)
      }, localDuration || duration)
    })
  }

  const clear = () => {
    clearTimeout(timer.current)
  }

  return [callTimeout, waiting, clear] as const
}
