import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { Capacitor } from '@capacitor/core'
import { Dialog, DialogPanel } from '@headlessui/react'
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import { Clock, Headphones01, Loading01, Microphone01 } from '@untitled-ui/icons-react'

import { ImageContent } from '@app/assets/ImageContent'
import { Button, Heading, LoaderWrapper, Switch, Text } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { InteractionType, LearningAssignmentType, Skill } from '@app/data'
import { useMicrophonePermission } from '@app/hooks'
import { useTranslation } from '@app/locales'
import { installAppStore } from '@app/modules/auth/store/installAppStore'
import { LessonProgressBar, TestTimeCountdown } from '@app/modules/lesson/components'
import { lessonInputsStore } from '@app/modules/lesson/store'
import { DEVELOPER_MODE } from '@app/modules/settings/model/constants'
import { showToast } from '@app/utils/commonUtils'
import { isBrowser, isMobile } from '@app/utils/platformUtils'
import { capitalizeFirstLetter } from '@app/utils/stringUtils'

import { useActiveTasks } from '../data'
import { FlashcardsSelection } from './FlashcardsSelection'

type Props = {
  onClose: () => void
}

export const StartTaskDialog = ({ onClose }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['training', 'common', 'error'])

  const [isPermissionMessageVisible, setIsPermissionMessageVisible] = useState(false)
  const [isDeveloperEnabled] = useLocalStorage(DEVELOPER_MODE, false)

  const [searchParams] = useSearchParams()
  const type = searchParams.get('type') || undefined

  const { activeTasks } = useActiveTasks()

  const { permissionState, requestMicrophone, isRequesting, canListen, canSpeak, onToggleInteractionsValue } =
    useMicrophonePermission()
  const { learningUnitEnrollmentId, assignmentType, skills, defaultName } = lessonInputsStore.useStore((store) => ({
    learningUnitEnrollmentId: store.nextFlashCardInput.learningExecutionId,
    assignmentType: store.assignmentType,
    skills: store.skills || [],
    defaultName: store.defaultName
  }))

  const executions = activeTasks || []
  const exercise = executions.find((goal) => goal.id === learningUnitEnrollmentId)
  const isTest = assignmentType === LearningAssignmentType.TEST
  const isHomeWork = assignmentType === LearningAssignmentType.HOMEWORK

  const isSpeakingRequired = skills.includes(Skill.SPEAKING)

  const onContinuePress = async () => {
    if (permissionState !== 'granted' && (canSpeak || (!canSpeak && isSpeakingRequired))) {
      const result = await requestMicrophone()

      if (result === 'granted') {
        navigate(Pages.LESSON_TRAINING, { replace: true })
      } else {
        setIsPermissionMessageVisible(true)
        installAppStore.setDialogVisible()
        showToast(t('permissions.microphone.error'), { type: 'error' })
      }
    } else {
      navigate(Pages.LESSON_TRAINING, { replace: true })
    }
  }

  const handleOnSettingsClick = () => {
    if (Capacitor.isNativePlatform()) {
      NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App
      })
    }
  }

  return (
    <Dialog static open onClose={onClose} className="relative z-[1002] w-full overflow-hidden">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 backdrop-blur"
      />
      <div className="fixed inset-0 flex w-screen items-center justify-center px-4">
        <DialogPanel
          as={motion.div}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          className="relative w-full max-w-[360px] overflow-hidden rounded-3xl"
        >
          <div className={classNames('relative flex h-full w-full flex-col')}>
            <LoaderWrapper
              loading={false}
              showChildrenOnLoading={false}
              withoutErrorImage={true}
              className="bg-brand-100"
            >
              <CardBg assignmentType={assignmentType} type={type} />

              <motion.div
                className={classNames(
                  'z-30 flex h-full min-h-[16rem] w-full flex-col items-center gap-4 bg-main-bg-gradient p-4 pb-4 shadow-btn-primary md:pb-8',
                  isMobile() && isBrowser() ? 'justify-start' : 'justify-between'
                )}
              >
                <div className="flex w-full flex-col gap-2 self-center md:gap-4">
                  <div
                    className={classNames(
                      'absolute left-0 mx-auto flex w-full flex-col items-center justify-center',
                      isHomeWork || isTest ? 'top-28' : 'top-32'
                    )}
                  >
                    <Heading size="m" className="text-center text-neutral50">
                      {capitalizeFirstLetter(
                        defaultName ||
                          exercise?.name ||
                          t(
                            `learningAssignmentType.${exercise?.learningAssignment.type.toLocaleLowerCase()}`
                          ).toUpperCase()
                      )}
                    </Heading>

                    {isHomeWork && (
                      <div className="inline-block self-center rounded-3xl bg-btn-primary-outline-bg-gradient">
                        <div className="flex items-center gap-2 py-[2px] pl-1 pr-[10px]">
                          <Clock className="h-5 w-5 text-[#733EEE]" />
                          <Text className="text-neutral800">
                            {t('deadline', { date: dayjs(exercise?.learningAssignment.endDateAndTime).fromNow() })}
                          </Text>
                        </div>
                      </div>
                    )}

                    {isTest && (
                      <div className="mx-auto flex w-[100px] flex-row items-center self-center rounded-3xl bg-btn-primary-outline-bg-gradient py-1.5">
                        <TestTimeCountdown />
                      </div>
                    )}
                  </div>

                  <div className="flex h-[48px] w-full items-center justify-start gap-4 px-2">
                    <LessonProgressBar progress={exercise?.progress || 0} hideCloudAnimations rocketSize="medium" />
                    <Text className="font-semibold text-white">{exercise?.progress || 0}%</Text>
                  </div>

                  {/* Input settings */}
                  <div
                    style={{ boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.05), 0px 4px 24px 0px rgba(0, 0, 0, 0.08)' }}
                    className="flex flex-col items-center justify-center gap-1 rounded-3xl bg-white bg-btn-primary-outline-bg-gradient px-4 py-2"
                  >
                    <div
                      className="flex w-full items-center justify-between"
                      onClick={() => {
                        onToggleInteractionsValue(InteractionType.SPEAKING)
                      }}
                    >
                      <div className="relative flex items-center gap-4">
                        <div className="flex size-8 flex-col items-center justify-center rounded-full bg-neutral50">
                          <Microphone01 className="size-7 p-1 text-brand-500" />
                        </div>
                        <Heading size="xxs">{t('canSpeak')}</Heading>
                        {isSpeakingRequired && (
                          <Text className="absolute -right-[48px] top-0 text-xs text-[#733EEE]" weight="bold">
                            {t('required')}
                          </Text>
                        )}
                      </div>

                      <Switch
                        colorType="primary"
                        value={canSpeak}
                        onValueChange={() => {
                          onToggleInteractionsValue(InteractionType.SPEAKING)
                        }}
                      />
                    </div>

                    <div
                      style={{ background: 'linear-gradient(180deg, #4C3CBB 0%, #C3BDFF 100%)' }}
                      className="h-[1px] w-full"
                    />

                    <div
                      className="flex w-full items-center justify-between"
                      onClick={() => {
                        onToggleInteractionsValue(InteractionType.LISTENING)
                      }}
                    >
                      <div className="relative flex items-center gap-4">
                        <div className="flex size-8 flex-col items-center justify-center rounded-full bg-neutral50">
                          <Headphones01 className="size-7 p-1 text-brand-500" />
                        </div>
                        <Heading size="xxs">{t('canListen')}</Heading>
                      </div>
                      <Switch
                        colorType="primary"
                        value={canListen}
                        onValueChange={() => {
                          onToggleInteractionsValue(InteractionType.LISTENING)
                        }}
                      />
                    </div>
                  </div>

                  {/* Developer settings */}
                  {isDeveloperEnabled && <FlashcardsSelection />}
                </div>

                {isPermissionMessageVisible && (
                  <div className="flex w-full flex-col gap-2">
                    {Capacitor.isNativePlatform() ? (
                      <>
                        <Text className="text-center text-neutral50">{t('permissions.microphone.description')}</Text>
                        <Text className="text-center text-neutral50 underline" onClick={handleOnSettingsClick}>
                          {t('permissions.openSettings')}
                        </Text>
                      </>
                    ) : (
                      <Text className="text-center text-neutral50">{t('permissions.microphone.description')}</Text>
                    )}
                  </div>
                )}

                <div className="mt-2 flex w-full flex-col gap-2">
                  <Button
                    className="w-full"
                    mode="primary"
                    onClick={onContinuePress}
                    disabled={(isSpeakingRequired && !canSpeak) || isRequesting}
                    icon={isRequesting ? <Loading01 className="animate-spin text-white0" /> : undefined}
                  >
                    {t('continue')}
                  </Button>
                </div>
              </motion.div>
            </LoaderWrapper>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
}

const CardBg = ({ assignmentType, type }: { assignmentType?: LearningAssignmentType; type?: string }) => {
  const bgImage1Src =
    assignmentType === LearningAssignmentType.TEST
      ? ImageContent.tasks.testBg1
      : assignmentType === LearningAssignmentType.HOMEWORK
        ? ImageContent.tasks.homeworkBg1
        : assignmentType === LearningAssignmentType.REPETITION ||
            assignmentType === LearningAssignmentType.PERSONALIZED_REPETITION
          ? ImageContent.tasks.repetitionBg1
          : ImageContent.tasks.selfStudyBg1

  const bgImage2Src =
    assignmentType === LearningAssignmentType.TEST
      ? ImageContent.tasks.testBg1 // TODO design missing
      : assignmentType === LearningAssignmentType.HOMEWORK
        ? ImageContent.tasks.homeworkBg2
        : assignmentType === LearningAssignmentType.REPETITION ||
            assignmentType === LearningAssignmentType.PERSONALIZED_REPETITION
          ? ImageContent.tasks.repetitionBg1 // TODO design missing
          : ImageContent.tasks.selfStudyBg1 // TODO design missing

  if (!assignmentType) {
    return null
  }

  return (
    <motion.div className="z-20 w-full overflow-hidden">
      <img src={type === 'even' ? bgImage1Src : bgImage2Src} className="h-48 w-full object-cover" />
      <div className="absolute inset-0 bg-gradient-to-b from-[hsl(0,0%,0%,0%)] to-[hsl(0,0%,0%,30%)]" />
    </motion.div>
  )
}
