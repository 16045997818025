import { useNavigate } from 'react-router-dom'

import { ImageContent } from '@app/assets/ImageContent'
import { Button } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import Constants from '@app/constants'
import { useTranslation } from '@app/locales'

import { useStudentInfo } from '../data'

export const AllDoneForToday = () => {
  const { t } = useTranslation(['common', 'dashboard'])
  const navigate = useNavigate()

  const { studentInfo } = useStudentInfo()

  return (
    <div className="relative flex w-full flex-col p-4 pt-8">
      <div className="relative mt-8 flex w-full justify-center">
        <img
          src={ImageContent.tasks.allDoneForToday}
          className="aspect-square max-w-[328px] self-center object-cover"
        />
      </div>

      <div className="mt-6 flex flex-col items-center gap-4 px-4">
        <h1 className="w-full text-center text-4xl font-bold text-[#F1F1F1]">{t('allDone.title')}</h1>
        <p className="w-full text-center font-light text-[#F1F1F1]">
          {t('allDone.description', { name: studentInfo?.student.userProfile.firstName })}
        </p>

        {Constants.ENABLE_LEARNING_PATH && (
          <Button
            className="w-full max-w-[480px]"
            onClick={() => {
              navigate(Pages.DASHBOARD_LEARNING_PATH)
            }}
          >
            {t('allDone.training')}
          </Button>
        )}
      </div>
    </div>
  )
}
