import { useEffect, useState } from 'react'

import { InteractionType } from '@app/data'
import { lessonInputsStore } from '@app/modules/lesson/store'
import { SpeechRecognitionPlugin } from '@app/plugins/SpeechRecognitionPlugin'
import { logError } from '@app/utils/logsUtils'
import { isNative } from '@app/utils/platformUtils'

export type UsePermissionState = 'granted' | 'denied' | 'prompt' | 'loading'

export function useMicrophonePermission() {
  const [permissionState, setPermissionState] = useState<UsePermissionState>('loading')
  const [isRequesting, setIsRequesting] = useState(false)

  const interactionTypes = lessonInputsStore.useStore((state) => state.nextFlashCardInput.interactionTypes)
  const canSpeak = interactionTypes.includes(InteractionType.SPEAKING)
  const canListen = interactionTypes.includes(InteractionType.LISTENING)

  useEffect(() => {
    checkPermission()
  }, [])

  const checkPermission = async () => {
    if (isNative()) {
      try {
        const { permission } = await SpeechRecognitionPlugin.hasPermission()

        setPermissionState(permission === 'granted' ? 'granted' : 'denied')
      } catch (error) {
        logError(error, 'useMicrophonePermission', 'checkPermission')
        setPermissionState('denied')
      }
    } else {
      if (!window.navigator.permissions) {
        // navigator.permissions not available in some browsers
        // https://caniuse.com/?search=navigator.permissions
        setPermissionState('denied')
        logError(
          new Error('No permission API'),
          'useMicrophonePermission',
          'checkPermission',
          'window.navigator.permissions does not exists'
        )
      } else {
        try {
          const result = await (window.navigator.permissions as any).query({ name: 'microphone' })
          setPermissionState(result.state)
        } catch (err) {
          setPermissionState('denied')
          logError(err, 'useMicrophonePermission', 'checkPermission', 'Error checking microphone permission')
        }
      }
    }
  }

  const requestMicrophone = async (): Promise<UsePermissionState> => {
    try {
      setIsRequesting(true)

      if (isNative()) {
        const { permission } = await SpeechRecognitionPlugin.requestPermission()
        const permissionState = permission === 'granted' ? 'granted' : 'denied'
        setPermissionState(permissionState)
        return permissionState
      } else {
        setIsRequesting(true)
        const stream = await window.navigator.mediaDevices.getUserMedia({
          audio: {
            noiseSuppression: true,
            echoCancellation: true // Optional: Enable echo cancellation
          }
        })
        setIsRequesting(false)

        // Stop all tracks to stop recording
        stream.getTracks().forEach((track) => track.stop())

        return 'granted'
      }
    } catch (err) {
      setIsRequesting(false)
      setPermissionState('denied')
      logError(err, 'useMicrophonePermission', 'requestMicrophone', 'Error requesting microphone permission', false)
      return 'denied'
    }
  }

  const onToggleInteractionsValue = (type: InteractionType, value?: boolean) => {
    let newCanSpeak = canSpeak
    let newCanListen = canListen

    if (type === InteractionType.SPEAKING) {
      newCanSpeak = value ?? !canSpeak
    }

    if (type === InteractionType.LISTENING) {
      newCanListen = value ?? !canListen
    }

    lessonInputsStore.setInteractionTypes(newCanSpeak, newCanListen)
  }

  return {
    permissionState,
    isRequesting,
    canListen,
    canSpeak,
    requestMicrophone,
    checkPermission,
    onToggleInteractionsValue
  }
}
