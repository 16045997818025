import { useEffect } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { motion } from 'framer-motion'
import Cookies from 'js-cookie'
import { XClose } from '@untitled-ui/icons-react'

import { ImageContent } from '@app/assets/ImageContent'
import { Button, Heading, Text } from '@app/components'
import { useTranslation } from '@app/locales'
import { isIOS, isTablet } from '@app/utils/platformUtils'

import { installAppStore } from '../../store/installAppStore'

const INSTALL_APP_BANNER_COOKIE_NAME = 'isBannerVisible'

/**
 * This banner is shown in device for the once if (isMobile + not native app)
 */
export const InstallAppBanner = () => {
  const isDialogVisible = installAppStore.useStore((state) => state.isDialogVisible)

  useEffect(() => {
    if (Cookies.get(INSTALL_APP_BANNER_COOKIE_NAME) !== 'false') {
      installAppStore.setDialogVisible()
    }
  }, [])

  if (!isDialogVisible) {
    return null
  }

  const handleOnContinueInBrowserClick = () => {
    installAppStore.setDialogInvisible()
    Cookies.set(INSTALL_APP_BANNER_COOKIE_NAME, 'false', { expires: 1, sameSite: 'strict' })
  }

  if (isTablet()) {
    return (
      <Dialog static open onClose={handleOnContinueInBrowserClick} className="relative z-[1003]">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 backdrop-blur"
        />
        <div className="fixed inset-0 flex w-screen items-center justify-center">
          <DialogPanel
            as={motion.div}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            className="mx-4 max-w-[400px] overflow-hidden rounded-3xl bg-gradient-to-b from-[#C3BDFF] to-[#F5F4FF] p-6 text-center shadow-2xl"
          >
            <div className="h-full w-full">
              <Content setIsBannerVisible={installAppStore.setDialogVisible} onClose={handleOnContinueInBrowserClick} />
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    )
  }

  return (
    <motion.div
      initial={{ y: 300 }}
      animate={{ y: 0 }}
      transition={{ duration: 1 }}
      className="fixed bottom-0 left-0 right-0 z-[1003] mx-auto min-h-[300px] max-w-[700px] overflow-hidden rounded-t-3xl bg-gradient-to-b from-[#C3BDFF] to-[#F5F4FF] p-6 text-center shadow-2xl lg:min-h-[250px]"
    >
      <Content setIsBannerVisible={installAppStore.setDialogVisible} onClose={handleOnContinueInBrowserClick} />
    </motion.div>
  )
}

const Content = ({
  setIsBannerVisible,
  onClose
}: {
  setIsBannerVisible: (value: boolean) => void
  onClose: () => void
}) => {
  const { t } = useTranslation('login')

  const handleOnInstallAppClick = () => {
    setIsBannerVisible(false)
    Cookies.set(INSTALL_APP_BANNER_COOKIE_NAME, 'false', { expires: 365, sameSite: 'strict' })

    const urlAddress = isIOS()
      ? 'https://apps.apple.com/cz/app/foxino/id6474036673'
      : 'https://play.google.com/store/apps/details?id=com.foxino.student&hl=en_US'

    window.open(urlAddress, '_blank')
  }

  return (
    <>
      <img src={ImageContent.common.iconFoxino} alt="Foxino icon" className="mx-auto h-[92px] w-[92px] rounded-3xl" />
      <div className="mx-auto flex w-full max-w-[700px] flex-col justify-center gap-5 py-4 pt-6">
        <Heading size="m" className="mb-4 text-[#2C0077]">
          {t('installAppBanner.title')}
        </Heading>
        <Button mode="primary" className="z-50" onClick={handleOnInstallAppClick}>
          {t('installAppBanner.installApp')}
        </Button>
        <Text size="l" className="z-50 !text-[#542AAF]" onClick={onClose}>
          {t('installAppBanner.continueInBrowser')}
        </Text>
      </div>
      <XClose
        className="absolute right-4 top-4 cursor-pointer text-neutral700 hover:brightness-110"
        onClick={onClose}
      />
    </>
  )
}
