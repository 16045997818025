import { useEffect, useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ImageContent } from '@app/assets/ImageContent'
import { IssueType } from '@app/modules/lesson/components/ExitLessonDialog/enums'
import { IssueReportMenu } from '@app/modules/lesson/components/ExitLessonDialog/IssueReportMenu'
import { IssueTypeSelectMenu } from '@app/modules/lesson/components/ExitLessonDialog/IssueTypeSelectMenu'
import { lessonActiveFlashCardStore } from '@app/modules/lesson/store'

type ExitLessonDialogProps = {
  isDialogVisible: boolean
  onClose: () => void
}

export const FeedbackDialog = ({ isDialogVisible, onClose }: ExitLessonDialogProps) => {
  const [issueType, setIssueType] = useState<IssueType>(IssueType.REPORT_BUG)

  useEffect(() => {
    lessonActiveFlashCardStore.clearStore()
  }, [])

  return (
    <AnimatePresence>
      {isDialogVisible && (
        <Dialog static open={isDialogVisible} onClose={onClose} className="relative z-[1003] w-full overflow-hidden">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur"
          />
          <div className="fixed inset-0 flex w-screen items-center justify-center px-4">
            <DialogPanel
              as={motion.div}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="relative w-full max-w-[360px] overflow-hidden rounded-3xl"
            >
              <div className="relative flex h-[528px] w-full flex-col bg-btn-primary-outline-bg-gradient">
                <img src={ImageContent.common.exitDialogBg} className="z-10 h-[142px] w-full object-cover" />

                <svg
                  className="absolute top-[calc(142px-44px)] z-20"
                  width="374"
                  height="44"
                  viewBox="0 0 374 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 115V9.99854C0 6.06962 2.86749 2.70597 6.76124 2.18138C86.1724 -8.51742 125.677 23.0627 214 33.6372C323.735 46.7753 341.766 28.4711 362.389 11.6957C367.259 7.73409 374 11.3101 374 17.5882V115C374 123.837 366.837 131 358 131H16C7.16344 131 0 123.837 0 115Z"
                    fill="url(#paint0_linear_763_31317)"
                  />
                  <path
                    d="M0 115V9.99854C0 6.06962 2.86749 2.70597 6.76124 2.18138C86.1724 -8.51742 125.677 23.0627 214 33.6372C323.735 46.7753 341.766 28.4711 362.389 11.6957C367.259 7.73409 374 11.3101 374 17.5882V115C374 123.837 366.837 131 358 131H16C7.16344 131 0 123.837 0 115Z"
                    fill="#C3BDFF"
                  />
                </svg>

                <div className="absolute bottom-0 z-30 w-full">
                  <Swiper className="h-[405px] max-w-[360px]" allowTouchMove={false}>
                    <SwiperSlide>
                      <IssueTypeSelectMenu onClose={onClose} setIssueType={setIssueType} isBackButtonVisible={false} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <IssueReportMenu onClose={onClose} issueType={issueType} />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}
