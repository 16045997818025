import { QueryHookOptions } from '@apollo/client'

import {
  GetCompletedLearningExecutionQuery,
  GetCompletedLearningExecutionQueryVariables,
  useGetCompletedLearningExecutionQuery
} from '@app/data'

export const useCompletedLearningExecution = (
  learningExecutionId?: string,
  baseOptions?: QueryHookOptions<GetCompletedLearningExecutionQuery, GetCompletedLearningExecutionQueryVariables>
) => {
  const query = useGetCompletedLearningExecutionQuery({
    variables: { learningExecutionId: learningExecutionId || '' },
    skip: !learningExecutionId,
    ...baseOptions
  })

  return {
    loading: query.loading,
    error: query.error,
    completedLearningExecution: query.data?.completedLearningExecution
  }
}
