import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { SearchSm, XClose } from '@untitled-ui/icons-react'

import { useTranslation } from '@app/locales'

type Props = {
  onSearch: (query: string) => void
  delay?: number
}

export const InputSearch = ({ onSearch, delay = 300 }: Props) => {
  const { t } = useTranslation('library')
  const [query, setQuery] = useState('')

  const debouncedOnSearch = useMemo(
    () =>
      debounce((value: string) => {
        if (onSearch) {
          onSearch(value)
        }
      }, delay),
    [onSearch, delay]
  )

  const handleChange = (e) => {
    const newValue = e.target.value
    setQuery(newValue)
    debouncedOnSearch(newValue)
  }

  const handleClear = () => {
    setQuery('')
    debouncedOnSearch('')
  }

  useEffect(() => {
    return () => {
      debouncedOnSearch.cancel()
    }
  }, [debouncedOnSearch])

  return (
    <div className="relative flex h-12 w-full items-center">
      <SearchSm className="absolute left-4 size-6 text-[#D8C5FF]" />

      <input
        style={{ boxShadow: '0px 4px 6px 0px rgba(81, 65, 219, 0.30) inset' }}
        className="h-full w-full rounded-3xl border-2 border-[#7141BF] bg-[#4E2298] px-4 pl-12 text-base font-semibold text-[#D8C5FF] caret-[#FF9A51] !outline-none selection:bg-primary400 placeholder:opacity-70 focus:ring-0"
        autoComplete="off"
        autoCapitalize="none"
        id="library_input"
        name="library_input"
        type="text"
        placeholder={t('searchPlaceholder')}
        value={query}
        onChange={handleChange}
      />

      {query && (
        <button
          type="button"
          onClick={handleClear}
          className="absolute right-2 flex h-8 w-8 items-center justify-center rounded-full bg-[#FF9A51] text-white hover:bg-[#FC4C00] focus:outline-none"
          aria-label="Clear"
        >
          <XClose />
        </button>
      )}
    </div>
  )
}
