import { PropsWithChildren, Suspense } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
} & PropsWithChildren

export const SettingsLayout = ({ children, className }: Props) => {
  return (
    <Suspense>
      <div
        className={classNames(
          'relative flex h-screen w-full max-w-[1312px] flex-col items-center overflow-y-auto pb-32 pt-safe',
          className
        )}
      >
        {children}
      </div>
    </Suspense>
  )
}
