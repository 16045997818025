import { useEffect, useRef, useState } from 'react'
import { Id, toast } from 'react-toastify'
import { WifiOff } from '@untitled-ui/icons-react'

import { Text } from '@app/components'
import { useTranslation } from '@app/locales'
import { showToast } from '@app/utils/commonUtils'

export const useCheckInternetConnection = (shouldShowToast = true) => {
  const { t } = useTranslation('error')
  const toastId = useRef<Id>()
  const [hasConnection, setHasConnection] = useState(false)

  useEffect(() => {
    const checkInternetConnection = () => {
      if (!navigator.onLine) {
        setHasConnection(false)

        if (shouldShowToast) {
          toastId.current = showToast(
            <div className="flex items-center gap-1">
              <Text>{t('noConnection')}</Text>
              <WifiOff width={16} height={16} />
            </div>,
            { type: 'error', draggable: true, autoClose: false }
          )
        }
      } else {
        setHasConnection(true)
        if (toastId.current) {
          toast.dismiss(toastId.current)
        }
      }
    }

    checkInternetConnection()

    window.addEventListener('online', checkInternetConnection)
    window.addEventListener('offline', checkInternetConnection)

    return () => {
      window.removeEventListener('online', checkInternetConnection)
      window.removeEventListener('offline', checkInternetConnection)
    }
  }, [])

  return { hasConnection }
}
