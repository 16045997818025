import { colors } from '@foxino/configs/tailwind/colors'
import classnames from 'classnames'
import { FormEvent, useRef } from 'react'

type SizeType = 'small' | 'big'

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'inputMode' | 'size' | 'value'> & {
  label?: string
  description?: string
  size?: SizeType
  indeterminate?: boolean
}

/**
 * @group Components
 * @category Component
 */
export const Checkbox = ({
  className,
  children,
  description,
  label,
  indeterminate,
  disabled,
  checked,
  onChange,
  size = 'small',
  ...restProps
}: Props) => {
  const ref = useRef<HTMLInputElement>(null!)

  if (ref.current) {
    ref.current.indeterminate = !!indeterminate
  }

  const handleOnChange = (event: FormEvent<HTMLInputElement>) => {
    event.stopPropagation()
    onChange?.(event)
  }

  return (
    <span className="flex" onClick={(e) => e.stopPropagation()}>
      <input
        type="checkbox"
        onChange={handleOnChange}
        disabled={disabled}
        checked={checked}
        className={classnames(
          'relative shrink-0 peer appearance-none rounded-md  border-[1px] border-grayNeutral-300',
          size === 'small' ? 'w-4 h-4 ml-[-2px]' : 'w-5 h-5 ml-[-4px] mt-[-2px]',
          'checked:bg-primary checked:border-primary indeterminate:bg-primary indeterminate:border-primary',
          !disabled && 'hover:checked:bg-brand-600 hover:border-brand-600 hover:bg-brand-25 active:border-[2px]',
          disabled
            ? 'cursor-not-allowed bg-grayNeutral-100 border-grayNeutral-200 checked:bg-grayNeutral-100 checked:border-grayNeutral-200'
            : 'cursor-pointer'
        )}
        ref={ref}
        {...restProps}
      />
      {label && (
        <div className={classnames('flex flex-col ml-2 text-sm mt-[-3px]')}>
          {label && <label className={classnames('text-gray-900', description && 'font-semibold')}>{label}</label>}
          {description && <span className="text-grayNeutral-500">{description}</span>}
        </div>
      )}
      {indeterminate ? (
        <svg
          className="absolute w-3 h-3 mt-[2px] hidden peer-checked:block peer-indeterminate:block pointer-events-none"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M2.91797 7H11.0846"
            stroke={disabled ? colors.grayNeutral[200] : '#FFFFFF'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          className="absolute w-3 h-3 mt-[2px] hidden peer-checked:block pointer-events-none"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path
            d="M10 3L4.5 8.5L2 6"
            stroke={disabled ? colors.grayNeutral[200] : '#FFFFFF'}
            strokeWidth="1.6666"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </span>
  )
}
