import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useToggle } from 'react-use'
import { motion, useMotionValue } from 'framer-motion'

import { LoaderWrapper } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'
import { MainHeader, NoClassSection } from '@app/modules/common'
import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'
import { useStudentInfo } from '@app/modules/tasks/data'
import { isCurrentPathActive, showToast } from '@app/utils/commonUtils'
import { logError } from '@app/utils/logsUtils'

import { useStudentsRanks } from '../../data/useStudentsRanks'
import { LeaderBoard } from './LeaderBoard'

export const LeaderBoardPage = () => {
  const [isSwitchClassBannerVisible, toggleSwitchClassBanner] = useToggle(false)

  const { t } = useTranslation('dashboard')
  const { pathname } = useLocation()
  const classroomId = localSettingsStore.useStore((state) => state.classroomId)

  const { studentInfo, loading: loadingStudentInfo, refetching: refetchingStudentInfo } = useStudentInfo()

  const {
    error: errorRanks,
    refetch: refetchRanks,
    loading: loadingRanks,
    refetching: refetchingRanks
  } = useStudentsRanks(classroomId)

  const dataTypeSwitchOpacity = useMotionValue(1)

  useEffect(() => {
    if (errorRanks) {
      logError(errorRanks, 'RanksScreen', 'useEffect')
      showToast(t('errorGlobal'), { type: 'error', toastId: 'RanksScreen' })
    }
  }, [errorRanks])

  useEffect(() => {
    if (isCurrentPathActive(pathname, Pages.DASHBOARD_LEADER_BOARD, true) && classroomId) {
      refetchRanks()
    }
  }, [pathname])

  const loading = loadingRanks || refetchingRanks || loadingStudentInfo || refetchingStudentInfo

  return (
    <div className="relative flex h-full w-full flex-col items-center overflow-hidden overscroll-none pt-safe">
      <motion.div style={{ opacity: dataTypeSwitchOpacity }} className="w-full">
        <MainHeader
          isSwitchClassEnable
          isSwitchClassBannerVisible={isSwitchClassBannerVisible}
          toggleSwitchClassBanner={toggleSwitchClassBanner}
        />
      </motion.div>

      <LoaderWrapper loading={loading} className="flex h-full w-full flex-col items-center justify-center">
        {!loading && (!studentInfo?.student.classrooms || studentInfo?.student.classrooms.length === 0) ? (
          <NoClassSection />
        ) : (
          <LeaderBoard isSwitchClassBannerVisible={isSwitchClassBannerVisible} />
        )}
      </LoaderWrapper>
    </div>
  )
}
