import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { PronunciationInput } from '@app/data'

type SpeachCounter = {
  started: number
  recognizing: number
  finished: number
  errorCount: number
  errorText: string
}

type CognitiveServicesStoreType = {
  isInitializing: boolean
  isRecognizing: boolean
  isRecognized: boolean
  recognizedText: string
  pronunciationAssessment?: PronunciationInput
  phraseList: string[] // a phraseList is a list of words or phrases provided ahead of time to help improve their recognition,
  microphoneEnabled: boolean
  counterData: SpeachCounter
}

const initialState: CognitiveServicesStoreType = {
  isInitializing: false,
  isRecognizing: false,
  isRecognized: false,
  recognizedText: '',
  phraseList: [],
  microphoneEnabled: false,
  counterData: {
    started: 0,
    errorCount: 0,
    recognizing: 0,
    finished: 0,
    errorText: ''
  }
}

const useStore = createWithEqualityFn<CognitiveServicesStoreType>(() => initialState, shallow)

export const cognitiveServicesStore = {
  setIsRecognizing: (isRecognizing: boolean): void => {
    useStore.setState({ isRecognizing, microphoneEnabled: true })
  },
  setIsInitializing: (isInitializing: boolean): void => {
    useStore.setState({ isInitializing })
  },
  setIsRecognized: (isRecognized: boolean): void => {
    useStore.setState({ isRecognized })
  },
  setRecognizedText: (recognizedText: string): void => {
    useStore.setState({ recognizedText })
  },
  setPronunciationAssessment: (pronunciationAssessment: PronunciationInput | undefined): void => {
    useStore.setState({ pronunciationAssessment })
  },
  addToPhraseList: (phrase: string, replace?: boolean): void => {
    useStore.setState((store) => ({
      phraseList: replace ? [phrase] : [...store.phraseList, phrase]
    }))
  },

  setCounterData: (counterData: SpeachCounter | ((prev: SpeachCounter) => SpeachCounter)): void => {
    if (typeof counterData === 'function') {
      useStore.setState((prev) => ({ counterData: counterData(prev.counterData) }))
    } else {
      useStore.setState({ counterData })
    }
  },

  clearStore: (): void => {
    useStore.setState(initialState, true)
  },

  useStore
}
