import { useEffect } from 'react'
import { useToggle } from 'react-use'
import { Dialog, DialogPanel } from '@headlessui/react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'

import { ImageContent } from '@app/assets/ImageContent'
import { SvgContent } from '@app/assets/SvgContent'
import { Button, LoaderWrapper, Text } from '@app/components'
import { StudentLibraryRankLevel } from '@app/data'
import { useTranslation } from '@app/locales'
import { LessonProgressBar } from '@app/modules/lesson/components'
import { getImageUrlFromImageType } from '@app/utils/imageUtils'

import { useFavoriteToggleHandler } from '../actions/useFavoriteToggleHandler'
import { useStartPackageHandler } from '../actions/useStartPackageHandler'
import { useStudentLibraryDetail } from '../data/useStudentLibraryDetail'
import { getBgColor } from '../utils/getBgColor'
import { LevelSwitch } from './LevelSwitch'

type Props = {
  subPackageId: string
  isDialogVisible: boolean
  onClose: () => void
  openDetail: (id: string) => void
}

export const StartSubPackageDialog = ({ subPackageId = '', isDialogVisible, onClose, openDetail }: Props) => {
  const { t } = useTranslation('library')

  const {
    detail: currentDetail,
    error: detailError,
    loading: detailLoading,
    refetch: refetchDetail
  } = useStudentLibraryDetail({ skip: !subPackageId, variables: { subPackageId } })

  const [isFavoriteFlag, toggleIsFavoriteFlag] = useToggle(currentDetail?.isFavorite || false)

  useEffect(() => {
    if (currentDetail) {
      toggleIsFavoriteFlag(currentDetail?.isFavorite)
    }
  }, [currentDetail])

  useEffect(() => {
    refetchDetail()
  }, [isDialogVisible])

  // get the highest enabled level
  const currentLevel =
    currentDetail?.levels.find((lvl) => lvl.level === StudentLibraryRankLevel.LEGEND && lvl.isEnabled) ??
    currentDetail?.levels.find((lvl) => lvl.level === StudentLibraryRankLevel.PRO && lvl.isEnabled) ??
    currentDetail?.levels.find((lvl) => lvl.level === StudentLibraryRankLevel.ROOKIE)

  const handleShowDetail = () => {
    if (subPackageId) {
      openDetail(subPackageId)
      onClose()
    }
  }

  const { handleStartPackage, isRequesting } = useStartPackageHandler({ id: subPackageId, currentDetail, currentLevel })
  const { handleOnFavoriteClick } = useFavoriteToggleHandler({ id: subPackageId, isFavoriteFlag, toggleIsFavoriteFlag })

  if (!subPackageId || !currentLevel || currentDetail == null) {
    return null
  }

  const getTimeDescription = () => {
    if (!currentLevel?.isStarted && currentLevel?.progress == null) {
      return t('duration') + dayjs.duration(currentLevel?.estimatedDurationSeconds || 0, 'seconds').humanize()
    } else if (currentLevel?.progress && currentLevel?.progress < 100) {
      return t('remains') + dayjs.duration(currentLevel?.remainingDurationSeconds || 0, 'seconds').humanize()
    } else {
      return t('completedIn') + dayjs.duration(currentLevel?.finishedDurationSeconds || 0, 'seconds').humanize()
    }
  }

  const imageUrl = getImageUrlFromImageType(currentDetail?.image)

  return (
    <Dialog static open={isDialogVisible} onClose={onClose} className="relative z-[1002] w-full overflow-hidden">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 backdrop-blur"
      />
      <div className="fixed inset-0 flex w-screen items-center justify-center px-4">
        <DialogPanel
          as={motion.div}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          className="relative w-full max-w-[360px] overflow-hidden rounded-3xl"
        >
          <div
            className={classNames('relative flex w-full flex-col', currentLevel.isStarted ? 'h-[28rem]' : 'h-[25rem]')}
          >
            <LoaderWrapper
              loading={detailLoading}
              showChildrenOnLoading={false}
              error={!!detailError}
              withoutErrorImage={true}
              className={classNames({ detailError: 'bg-brand-100' })}
            >
              <div
                style={{ background: getBgColor(currentDetail?.imageBackground) }}
                className="relative h-[250px] w-full"
              >
                <img src={imageUrl} className="z-10 h-full w-full object-cover" />
              </div>
              <div className="absolute inset-0 bg-gradient-to-b from-[#40009f1a] to-[hsl(264,100%,30%)]" />

              <div className="relative h-full w-full bg-main-bg-gradient">
                <div className="absolute -top-[120px] z-40 flex w-full flex-col items-center justify-center">
                  <div className="line-clamp-1 text-center text-4xl font-extrabold text-neutral50">
                    {currentDetail?.name}
                  </div>
                  <div className="flex flex-row items-center justify-between gap-1 font-[600] text-neutral50">
                    <img className="h-6 w-6 object-contain" src={SvgContent.library.clockFilled} alt="clockFilled" />
                    {getTimeDescription()}
                  </div>

                  <div className="mt-3 w-full max-w-[360px]">
                    <LevelSwitch readonly={true} level={currentLevel.level} />
                  </div>
                </div>

                <div className="flex h-full w-full flex-col justify-between py-2">
                  <div className="flex w-full flex-col">
                    <div className="flex w-full flex-row items-start justify-between gap-2 px-4 pb-0">
                      {/* descriptions */}
                      <div className="flex w-full flex-col gap-2 pt-2">
                        <div className="flex flex-row items-center gap-2 font-[600] text-neutral50">
                          <img className="h-6 w-6 object-contain" src={SvgContent.library.bookmark} alt="bookmark" />
                          {currentDetail?.packageName}
                        </div>
                        <div className="flex flex-row items-center gap-2 font-[600] text-neutral50">
                          <div className="flex h-6 w-6 items-center justify-center">
                            <img className="h-5 w-5 object-center" src={SvgContent.library.chatBuble} alt="chatBuble" />
                          </div>
                          {currentLevel.category.map((item) => t(`category.${item.toString()}`)).join(', ')}
                        </div>
                      </div>

                      {/* Favorite icon */}
                      <div
                        style={{
                          backgroundImage: `url(${ImageContent.library.favoriteEclipse})`,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}
                        className="flex h-12 w-12 cursor-pointer items-center justify-center"
                        onClick={handleOnFavoriteClick}
                      >
                        <motion.img
                          className="w-7"
                          src={
                            isFavoriteFlag ? ImageContent.library.favoriteHeart : ImageContent.library.favoriteInactive
                          }
                          alt="favoriteEclipse"
                          initial={{ scale: 0.8, opacity: 0 }}
                          animate={{
                            scale: isFavoriteFlag ? 1.1 : 1,
                            opacity: isFavoriteFlag ? 1 : 0.8
                          }}
                          transition={{ type: 'spring', stiffness: 300, damping: 15 }}
                        />
                      </div>
                    </div>

                    {currentLevel.isStarted && (
                      <div className="flex h-[48px] w-full items-center justify-start gap-4 px-4 pr-6">
                        <LessonProgressBar
                          progress={currentLevel.progress || 0}
                          hideCloudAnimations
                          rocketSize="medium"
                        />
                        <Text className="font-semibold text-white">{currentLevel.progress || 0}%</Text>
                      </div>
                    )}
                  </div>

                  <div className="flex w-full flex-row items-center justify-between gap-x-4 px-4">
                    <Button className="w-full" onClick={handleShowDetail}>
                      {t('detail')}
                    </Button>
                    <Button
                      className="w-full"
                      mode="secondary"
                      onClick={handleStartPackage}
                      labelClassName="leading-none"
                      disabled={isRequesting}
                    >
                      {currentLevel?.progress == null
                        ? t('start')
                        : currentLevel?.progress === 100
                          ? t('startAgain')
                          : t('continue')}
                    </Button>
                  </div>
                </div>
              </div>
            </LoaderWrapper>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
}
