import { useEffect } from 'react'
import { useMeasure } from 'react-use'
import classNames from 'classnames'
import { useAnimate } from 'framer-motion'

import { ImageContent } from '@app/assets/ImageContent'
import { SvgContent } from '@app/assets/SvgContent'

import './LessonProgressBar.styles.css'

const sizeToStyle: Record<'small' | 'medium' | 'large', string> = {
  small: 'h-5',
  medium: 'h-6',
  large: 'h-7'
}

type LessonProgressBarProps = {
  progress: number
  hideCloudAnimations?: boolean
  rocketSize?: 'small' | 'medium' | 'large'
}

const MIN_ROCKET_X = 52 // because the rocket's width is about 52px
const ROCKET_LIGHT = 40

export const LessonProgressBar = ({
  progress,
  hideCloudAnimations = false,
  rocketSize = 'large'
}: LessonProgressBarProps) => {
  const [rocketScope, rocketAnimate] = useAnimate()
  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const sizeStyle = sizeToStyle[rocketSize]

  useEffect(() => {
    const newX = (width / 100) * progress - ROCKET_LIGHT
    rocketAnimate(
      rocketScope.current,
      { x: newX < 0 ? 0 : width - newX < MIN_ROCKET_X ? width - MIN_ROCKET_X : newX },
      { duration: 0.5 }
    )
  }, [progress, width])

  return (
    <div ref={ref} className="relative flex h-full w-full items-center justify-start">
      <img
        className="pointer-events-none absolute z-10 h-[18px] w-full rounded-full"
        src={ImageContent.lesson.loadingBarBg}
      />
      <div
        style={{ width: `${progress}%`, backgroundImage: 'linear-gradient(90deg, #FF6392 0%, #FF8B39 100%)' }}
        className="pointer-events-none z-20 mx-1 h-[12px] min-w-[24px] rounded-full transition-all delay-75 duration-500"
      />
      <div
        style={{ width: `${progress}%`, overflow: 'hidden' }}
        className="pointer-events-none absolute z-20 mx-1 h-[4px] min-w-[24px] rounded-full"
      >
        <div
          style={{
            width: '95%',
            backgroundImage: 'linear-gradient(90deg, #FFFFFF00 0%, #FFEA29 100%)',
            opacity: progress / 100
          }}
          className="h-full transition-all delay-75 duration-500"
        />
      </div>

      <div ref={rocketScope} className="absolute left-0 z-40 flex h-full items-center">
        <img className="-mr-2" alt="rocket-light" src={SvgContent.lesson.rocketLight} />
        <img src={'/3d/rocket-foxino.gif'} alt="Rocket" className={classNames('z-30', sizeStyle)} />
      </div>

      {!hideCloudAnimations && (
        <>
          <img className="cloud-animation1 pointer-events-none" src={ImageContent.lesson.lessonCloud} alt="Cloud1" />
          <img className="cloud-animation2 pointer-events-none" src={ImageContent.lesson.lessonCloud} alt="Cloud2" />
        </>
      )}
    </div>
  )
}
