import { ButtonHTMLAttributes, cloneElement, ReactElement, Ref, SVGProps } from 'react'
import classnames from 'classnames'

import { Text } from '..'

export type IconType = (props: SVGProps<SVGSVGElement>) => JSX.Element

type ModeType = 'primary' | 'primary-outline' | 'secondary' | 'outline'
type IconSideType = 'left' | 'right'
type SizeType = 'large' | 'medium' | 'small' | 'xs'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  btnClassName?: string
  labelClassName?: string
  disabled?: boolean
  icon?: ReactElement<IconType> | ReactElement<any>
  iconSide?: IconSideType
  mode?: ModeType
  ref?: Ref<HTMLButtonElement>
  size?: SizeType
  rounded?: SizeType
}

const modeToDivWrapperBgStyle: Record<ModeType, string> = {
  primary: 'linear-gradient(45deg, rgba(222, 217, 255, 0.3) 0%, #534897 100%)',
  'primary-outline': 'transparent',
  secondary: '#FFAF74',
  outline: 'transparent'
}

const modeToDivWrapperPaddingStyle: Record<ModeType, string> = {
  primary: '2px',
  'primary-outline': '0px',
  secondary: '2px',
  outline: '0px'
}

const modeToBtnBgStyle: Record<ModeType, string> = {
  primary: 'bg-btn-primary-bg-gradient',
  'primary-outline': 'bg-btn-primary-outline-bg-gradient',
  secondary: 'bg-btn-secondary-bg-gradient',
  outline: 'transparent'
}

const modeToBtnBgHoverStyle: Record<ModeType, string> = {
  primary: 'hover:bg-btn-primary-bg-hover-gradient',
  'primary-outline': 'hover:bg-btn-primary-outline-bg-hover-gradient',
  secondary: 'hover:bg-btn-secondary-bg-hover-gradient',
  outline: ''
}

const modeToBtnShadowStyle: Record<ModeType, string> = {
  primary: 'shadow-btn-primary',
  'primary-outline': 'none',
  secondary: 'shadow-btn-secondary',
  outline: 'none'
}

const modeToBtnTextStyle: Record<ModeType, string> = {
  primary: 'text-neutral50',
  'primary-outline': 'text-[#2C0077]',
  secondary: 'text-neutral50',
  outline: 'text-[#2C0077]'
}

/**
 * @group Components
 * @category Props
 */
export const Button = ({
  className,
  children,
  // custom props
  btnClassName,
  labelClassName,
  disabled,
  icon,
  iconSide = 'left',
  mode = 'primary',
  size = 'medium',
  type,
  ...restProps
}: Props) => {
  // wrapper div
  const divWrapperBgStyle = modeToDivWrapperBgStyle[mode]
  const divWrapperPaddingStyle = modeToDivWrapperPaddingStyle[mode]

  // inner button
  const btnBgStyle = modeToBtnBgStyle[mode]
  const btnBgHoverStyle = modeToBtnBgHoverStyle[mode]
  const btnBoxShadowStyle = modeToBtnShadowStyle[mode]
  const btnTextStyle = modeToBtnTextStyle[mode]

  return (
    <div
      style={{
        background: divWrapperBgStyle,
        padding: divWrapperPaddingStyle,
        ...restProps.style
      }}
      className={classnames('h-12 rounded-[24px] py-2', className)}
    >
      <button
        style={{ boxShadow: btnBoxShadowStyle }}
        className={classnames(
          'flex h-full w-full items-center justify-center gap-2 rounded-[24px] px-4',
          disabled ? 'cursor-grab opacity-60' : 'cursor-pointer',
          iconSide === 'left' ? 'flex-row' : 'flex-row-reverse',
          disabled ? btnBgStyle : `${btnBgStyle} ${btnBgHoverStyle}`,
          btnTextStyle,
          btnClassName
        )}
        disabled={disabled}
        type={type}
        {...restProps}
      >
        {icon && cloneElement(icon, { className: classnames(icon.props.className, size === 'xs' && 'scale-75') })}

        {typeof children === 'string' ? (
          <Text size="l" weight="bold" className={classnames(!icon && 'w-full', labelClassName)}>
            {children}
          </Text>
        ) : (
          children
        )}
      </button>
    </div>
  )
}
