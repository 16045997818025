import { HTMLAttributes } from 'react'
import { ChevronLeft } from '@untitled-ui/icons-react'

export const GoBackBtn = ({ onClick }: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className="flex size-10 items-center justify-center rounded-full bg-btn-primary-outline-bg-gradient p-2 shadow-card lg:size-12 lg:p-3"
      onClick={onClick}
    >
      <ChevronLeft className="text-[#52287C]" />
    </button>
  )
}
